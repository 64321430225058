import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import shopifyBanner from "../../../Assets/Images/story/moneyIncome.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersShopify from "../../../Assets/Images/banners/Shopıfy Market Management.webp";

export default function ShopifyMarketManagement() {
    const bannerHeader = "Strategic Shopify Solutions";
    const bannerTxt = "Maximize your Shopify store's potential with Ra Digital. Our tailored services cater to both established sellers and new ventures, enhancing your brand, boosting sales, and ensuring success on the Shopify platform.";

    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersShopify}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Our Expertise
                        </Typography>
                        <Typography color="white" variant="lead">
                            At <b>Ra Digital</b> , we offer extensive expertise and a fervent commitment to fostering
                            business
                            success in the ever-evolving realm of e-commerce. Our dedicated team specializes in adeptly
                            navigating the complexities of the Shopify platform, providing customized solutions that
                            align with your distinctive business objectives.
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Our Comprehensive Service Portfolio
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Shopify Store Setup and Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Launch your Shopify store confidently with our expert guidance, ensuring
                                    optimization for a seamless and engaging customer experience.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Product Listing and Catalog Management
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Effectively showcase your products with our catalog management services, optimizing
                                    listings for maximum visibility and appeal to make your store a preferred
                                    destination.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    SEO (Search Engine Optimization)
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Strategically set prices to remain competitive while maximizing profitability. Our
                                    team conducts thorough market research and analysis to help you determine the
                                    optimal pricing strategy for your Etsy products.

                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Price and Profit Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Maximize profitability with competitive pricing. Our team conducts thorough market
                                    research to help set optimal prices, maintaining a healthy balance between
                                    competitiveness and profit margins.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Market Analysis and Trend Monitoring
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay ahead of market trends through in-depth analysis. We monitor industry trends,
                                    analyze competitor activities, and track customer behavior, providing insights to
                                    inform your Shopify strategy.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Your Shopify Success?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Your Shopify Success?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        <b>Proven Track Record: </b>
                                        Ra Digital boasts a demonstrated history of facilitating the thriving success of
                                        Shopify sellers and contributing to the establishment of prosperous Shopify
                                        marketplaces.
                                    </li>
                                    <li>
                                        <b>Comprehensive End-to-End Solutions: </b>
                                        From initial store setup to meticulous expansion strategies, we provide holistic
                                        services that address every facet of your Shopify journey with precision and
                                        expertise.
                                    </li>
                                    <li>
                                        <b>Client-Centric Approach: </b>
                                        Recognizing the uniqueness of each business, our strategies are meticulously
                                        tailored to align with your specific goals, ensuring a personalized and
                                        effective path to success.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Ready to elevate your Shopify store or embark on the journey of opening a Shopify marketplace?
                        Contact Ra Digital today to explore how our tailored services can maximize your Shopify
                        account's potential, increase sales, and drive sustainable success on the Shopify platform."
                    </Typography>
                </div>
            </section>
        </div>


    )
}