import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import seoBanner from "../../../Assets/Images/story/seo.svg";
import seoBrowser from "../../../Assets/Images/story/seoBrowser.svg";
import bannersSeo from "../../../Assets/Images/banners/SEO.webp";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";

export default function SEOOptimization() {
    const bannerHeader = "Mastering Visibility with SEO Optimization";
    const bannerTxt = "Enhance your online presence with our SEO optimization service, ensuring prime visibility for your brand across the digital landscape."
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersSeo}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Our SEO Optimization Approach
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        In the competitive online retail landscape, visibility is key. Our SEO Optimization service
                        ensures your products take center stage in customer searches. Leveraging advanced algorithms and
                        staying ahead of Amazon's evolving search engine mechanics, we offer:
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Key Elements of SEO Optimization
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    In-depth Keyword Research and Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Target both short-tail and long-tail phrases with comprehensive keyword research and
                                    optimization.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Product Titles, Descriptions, and Backend Search Terms Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Optimize product titles, descriptions, and backend search terms for maximum
                                    visibility on Amazon.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Regular Performance Analysis and Adjustments
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay ahead of algorithm changes and market trends with regular performance analysis
                                    and adjustments.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for SEO Optimization?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for SEO Optimization?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Advanced Algorithms: We leverage advanced algorithms to ensure your products
                                        gain maximum visibility in customer searches.
                                    </li>
                                    <li>
                                        Comprehensive Optimization: From keyword research to product titles and
                                        descriptions, we optimize every aspect for enhanced visibility on Amazon.
                                    </li>
                                    <li>
                                        Adaptability: Our regular performance analysis and adjustments keep you ahead
                                        of algorithm changes and market trends.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="flex justify-center lg:w-[45%] w-full max-h-[325x] nbXs:max-h-1/2">
                        <img src={seoBrowser} alt="experts"
                             className="lg:w-full sm:w-2/3 w-full  max-h-full object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
