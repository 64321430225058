import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import amazonDsp from "../../../Assets/Images/serviceDetailBanner/amazon-dsp.webp";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import {useParams} from "react-router-dom";


export default function AmazonMarketManagement() {
    let {service_name} = useParams();
    service_name = "amazon-market-management" ? "Amazon Market Management" : "Amazon Market Management";
    const bannerTxt = "Welcome to Ra Digital, Your Premier Partner for Amazon Marketplace Success. Specializing in comprehensive services, we navigate and optimize your brand presence on one of the world's largest online marketplaces, ensuring a successful and profitable journey."
    return (

        <div className="container">
            <div className="row">
                <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={amazonDsp}/>
            </div>

            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Our Proficiency
                        </Typography>
                        <Typography color="white" variant="lead">
                            At Ra Digital, our team is committed to harnessing our expertise in the Amazon Marketplace
                            to propel your business to new heights. Offering a spectrum of services, from catalog
                            management to brand registry consultation, we provide tailored solutions to meet the
                            distinctive needs of your brand.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Services We Offer
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Catalog Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Elevate your product listings for optimal visibility and customer engagement. Our
                                    catalog management services ensure that your products stand out, capturing the
                                    attention of potential buyers.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    SEO Excellence
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Enhance product discoverability through effective SEO strategies. We align your
                                    product listings with Amazon's search algorithms, significantly increasing
                                    visibility in relevant searches.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Pricing and Profit Maximization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Set competitive prices while maximizing profitability. Our team conducts
                                    comprehensive market research to determine the optimal pricing strategy for your
                                    products.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Market Analysis and Insightful Trends
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay ahead of the competition with in-depth market analysis. We monitor industry
                                    trends, competitor activities, and customer behavior, providing insights to shape
                                    your business strategy on the Amazon Marketplace.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Promotion and Visibility Management
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Drive sales and enhance visibility with targeted promotions and discounts. Our team
                                    designs and implements effective campaigns aligned with your business goals,
                                    resonating with your target audience.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Your Amazon Marketplace Success?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Your Amazon Marketplace Success?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        <b>Proven Track Record: </b>
                                        Our success stories speak volumes about our capability to drive businesses to
                                        success on the Amazon Marketplace.
                                    </li>
                                    <li>
                                        <b>Tailored Solutions: </b>
                                        We recognize the uniqueness of each brand, and our services are meticulously
                                        customized to align with your specific goals, ensuring a personalized approach
                                        to your success.
                                    </li>
                                    <li>
                                        <b>Comprehensive Expertise: </b>
                                        From catalog management to brand protection, our comprehensive expertise covers
                                        all aspects of your Amazon journey, providing a one-stop solution for your
                                        e-commerce needs.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        Ready to optimize your presence on the Amazon Marketplace? Contact Ra Digital today to explore
                        how our tailored services can elevate your brand, increase sales, and drive sustainable success
                        on Amazon.
                    </Typography>
                </div>
            </section>
        </div>


    )
}