import React from 'react';
import {useParams} from "react-router-dom";
import MainLayout from "../../Layout/MainLayout";
import AmazonAds from "./Detail/amazonAds";
import AmazonDsp from "./Detail/amazonDsp";
import GoogleAds from "./Detail/googleAds";
import MetaAds from "./Detail/metaAds";
import WalmartMarketManagement from "./Detail/walmartMarketManagement";
import EtsyMarketManagement from "./Detail/etsyMarketManagement";
import ShopifyMarketManagement from "./Detail/shopifyMarketManagement";
import AmazonMarketManagement from "./Detail/amazonMarketManagement";
import Website from "./Detail/website";
import TrendyolStoreManagement from "./Detail/trendyolStore";
import APlusContentCreation from "./Detail/aPlusContent";
import SEOOptimization from "./Detail/seoOptimization";
import BrandRegistry from "./Detail/brandRegistery";
import StorePageDesign from "./Detail/storePageDesign";
import SocialMediaManagement from "./Detail/socialMedia";
import LogisticManagement from "./Detail/logistic";
import NotFound from "../404/NotFound";


export const ServiceDetail = () => {
    const {service_name} = useParams();
    let content = null;
    switch (service_name) {
        // AMAZON ADVERTISING
        case 'amazon-ads':
            content = (
                <div>
                    <AmazonAds/>
                </div>
            )
            break;
        case 'amazon-dsp':
            content = (
                <div>
                    <AmazonDsp/>
                </div>
            )
            break;
        // bu menüde gösterilmiyo
        case 'amazon-market-management':
            content = (
                <div>
                    <AmazonMarketManagement/>
                </div>
            )
            break;

        //     AMAZON STORE MANAGEMENT
        case 'a-plus-content':
            content = (
                <div>
                   <APlusContentCreation/>
                </div>
            )
            break;
        case 'seo':
            content = (
                <div>
                   <SEOOptimization/>
                </div>
            )
            break;
        case 'brand-registry':
            content = (
                <div>
                  <BrandRegistry/>
                </div>
            )
            break;
        case 'store-page-design':
            content = (
                <div>
                    <StorePageDesign/>
                </div>
            )
            break;
        case 'social-media-management':
            content = (
                <div>
                    <SocialMediaManagement/>
                </div>
            )
            break;
        case 'inventory-management':
            content = (
                <div>
                    <LogisticManagement/>
                </div>
            )
            break;
        //     MARKETPLACE MANAGEMENT
        case 'walmart-market-management':
            content = (
                <div>
                    <WalmartMarketManagement/>
                </div>
            )
            break;
        case 'etsy-market-management':
            content = (
                <div>
                    <EtsyMarketManagement/>
                </div>
            )
            break;
        case 'trendyol-store-management':
            content = (
                <div>
                    <TrendyolStoreManagement/>
                </div>
            )
            break;
        case 'shopify-market-management':
            content = (
                <div>
                    <ShopifyMarketManagement/>
                </div>
            )
            break;
        // D2C SOLUTIONS
        case 'google-ads':
            content = (
                <div>
                    <GoogleAds/>
                </div>
            )
            break;
        case 'meta-ads':
            content = (
                <div>
                    <MetaAds/>
                </div>
            )
            break;
        // case 'off-amazon-marketing':
        //     content = (
        //         <div>
        //             <h1 className="text-white">
        //                 off-amazon-marketing
        //                 <NotFound/>
        //             </h1>
        //         </div>
        //     )
        //     break;
        case 'website-management':
            content = (
                <div>
                    <Website/>
                </div>
            )
            break;
        default:
            content = (
                <NotFound/>
            );
    }
    return (
        <MainLayout>
            <div className="">
                {content}
            </div>
        </MainLayout>
    );
};