import React from 'react';
import {Link} from "react-router-dom";
import notFound from "../../Assets/Images/story/notFound.svg";
import {BsFacebook, BsInstagram, BsTwitter} from "react-icons/bs";
export default function NotFound() {
    return (
        <div className="flex flex-col items-center justify-center">

            <img src={notFound} alt="notFound" className="w-96 h-96"/>

                <h1 className="text-2xl font-bold text-gray-700 mb-2">404 Not Found</h1>

                <p className="text-gray-600 mb-6">The page you are looking for could not be found.</p>

                <Link to="/" className="text-white backgroundGradient py-2 px-4 rounded shadow">Go back
                    home</Link>

            <ul className="wrapper mt-12 gap-4">
                <Link to="#">
                    <li className="icon facebook">
                        <span className="tooltip">Facebook</span>
                        <BsFacebook/>
                    </li>
                </Link>
                <Link to="#">
                    <li className="icon twitter">
                        <span className="tooltip">Twitter</span>
                        <BsTwitter/>
                    </li>
                </Link>
                <Link to="https://www.instagram.com/radigital_io/">
                    <li className="icon instagram">
                        <span className="tooltip">Instagram</span>
                        <BsInstagram />
                    </li>
                </Link>

            </ul>
        </div>
)
}