import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import aPlusContentBanner from "../../../Assets/Images/story/aPlusContentBanner.svg";
import aPlusContentBottom from "../../../Assets/Images/story/aPlusContentBottom.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersAplus from "../../../Assets/Images/banners/A+.webp";

export default function APlusContentCreation() {
    const bannerHeader = "A+ Content Creation: Beyond Visual Appeal";
    const bannerTxt = "Our A+ content service blends innovative design with strategic marketing to captivate your audience. Elevate your brand narrative for maximum impact and conversions."

    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersAplus}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Our A+ Content Creation Approach
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Our industry veterans bring a wealth of experience to A+ Content Creation, combining aesthetic
                        appeal with strategic marketing insights. We offer customized graphic design, detailed
                        comparative charts, and enhanced brand storytelling through multimedia elements, ensuring your
                        content stands out on Amazon and drives customer engagement and retention.
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Key Elements of A+ Content Creation
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Customized Graphic Design
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Elevate your Amazon product pages with customized graphic design that aligns
                                    seamlessly with your brand identity.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Comparative Charts
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Highlight your product's unique selling points against competitors with detailed
                                    comparative charts.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Enhanced Brand Storytelling
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Go beyond visuals and create a compelling brand narrative with multimedia elements,
                                    improving customer engagement and retention.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        {/* Add more items as needed for A+ Content Creation */}
                        {/* ... */}
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for A+ Content Creation?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for A+ Content Creation?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Industry Veterans: Our team brings extensive experience to A+ Content Creation,
                                        ensuring your content combines visual appeal with strategic marketing insights.
                                    </li>
                                    <li>
                                        Customized Solutions: We offer tailored graphic design, comparative charts, and
                                        brand storytelling, providing unique solutions aligned with your brand's goals.
                                    </li>
                                    <li>
                                        Customer Engagement: Beyond visuals, we focus on driving customer engagement and
                                        retention through compelling narratives and multimedia elements.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                        <img src={aPlusContentBottom} alt="A+ Content Creation"
                             className="w-full max-h-full nbXs:max-h-[275px] drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            {/* Add more sections as needed for additional content */}
            {/* ... */}
        </div>
    );
}
