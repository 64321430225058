import React, {useEffect, useRef, useState} from 'react'
import "../../index.css"
import MainLayout from "../../Layout/MainLayout";
import servicesBanner from "../../Assets/Images/story/servicesBanner.svg"
import {Typography} from "@material-tailwind/react";
import {ScoolButton} from "../../Components/Buttons/ScoolButton";
import {motion} from "framer-motion";
import {ServicesCard} from "../../Components/Cards/servicesCard";
import {Link} from "react-router-dom";

export const Services = () => {
    const serviceInfosArr = [
        {
            title: "Amazon Advertising",
            description: "We help you to grow your business by creating and managing your social media accounts.",
            svg:
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="IconChangeColor" height="200"
                     width="200">
                    <path
                        d="M11.71,17.99C8.53,17.84,6,15.22,6,12c0-3.31,2.69-6,6-6c3.22,0,5.84,2.53,5.99,5.71l-2.1-0.63C15.48,9.31,13.89,8,12,8 c-2.21,0-4,1.79-4,4c0,1.89,1.31,3.48,3.08,3.89L11.71,17.99z M22,12c0,0.3-0.01,0.6-0.04,0.9l-1.97-0.59C20,12.21,20,12.1,20,12 c0-4.42-3.58-8-8-8s-8,3.58-8,8s3.58,8,8,8c0.1,0,0.21,0,0.31-0.01l0.59,1.97C12.6,21.99,12.3,22,12,22C6.48,22,2,17.52,2,12 C2,6.48,6.48,2,12,2S22,6.48,22,12z M18.23,16.26L22,15l-10-3l3,10l1.26-3.77l4.27,4.27l1.98-1.98L18.23,16.26z"
                        id="mainIconPathAttribute" fill="#ffffff"></path>
                    <linearGradient id="gradientColor">
                        <stop offset="5%" stopColor="#7eaaff"></stop>
                        <stop offset="95%" stopColor="#ff48fb"></stop>
                    </linearGradient>
                </svg>,
            link: "/services/avertising",
            //     sub array for subtitles
            sub: [
                {
                    subtitle: "Amazon PPC",
                    sub: [
                        {
                            subtitle: "Maximize Ad Visibility",
                        },
                        {
                            subtitle: "Optimize Bid Strategies",
                        },
                        {
                            subtitle: "Drive Conversions",
                        },
                        {
                            subtitle: "Keyword Research",
                        },
                    ]
                },
                {
                    subtitle: "Amazon DSP",
                    sub: [
                        {
                            subtitle: "Target Audience",
                        },
                        {
                            subtitle: "Dynamic Ad Campaigns",
                        },
                        {
                            subtitle: "Behavioral Targeting",
                        },
                        {
                            subtitle: "Measure Ad Performance",
                        },
                    ]
                },
            ]
        },
        {
            title: "Amazon Store Management",
            description: "Elevate your Amazon store with our comprehensive management services.",
            svg:
                <svg width="50px" height="50px" viewBox="0 0 1024 1024" fill="#000000" className="icon" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path
                            d="M512.014 751.792c-17.636 0-31.986-14.34-31.986-31.992s14.348-31.994 31.986-31.994c17.638 0 31.996 14.342 31.996 31.994s-14.358 31.992-31.996 31.992z m0-47.988c-8.818 0-15.988 7.186-15.988 15.996s7.17 15.996 15.988 15.996c8.82 0 16-7.186 16-15.996s-7.18-15.996-16-15.996zM360.156 959.782a8.012 8.012 0 0 1-7.82-9.73l31.994-143.97c0.96-4.312 5.288-6.966 9.544-6.078a8.012 8.012 0 0 1 6.076 9.544l-31.994 143.97a7.994 7.994 0 0 1-7.8 6.264zM663.89 959.782a8 8 0 0 1-7.81-6.264l-31.994-143.97a8.012 8.012 0 0 1 6.076-9.544c4.344-0.904 8.594 1.782 9.546 6.078l31.992 143.97a8.01 8.01 0 0 1-7.81 9.73z"
                            fill=""></path>
                        <path
                            d="M687.978 959.782H336.06a7.992 7.992 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h351.918c4.422 0 8 3.578 8 7.998a7.994 7.994 0 0 1-8 7.998zM903.936 672.092H120.104c-4.422 0-8-3.578-8-7.998V311.916a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v344.178h767.834V311.916a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v352.178a7.994 7.994 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M903.936 784.068H120.104c-30.87 0-55.988-25.12-55.988-55.988V311.916a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998V728.08c0 22.056 17.942 39.992 39.992 39.992h783.832c22.056 0 39.99-17.936 39.99-39.992V311.916a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998V728.08c0 30.87-25.122 55.988-55.988 55.988zM951.924 128.206a7.994 7.994 0 0 1-7.998-7.998c0-22.05-17.934-39.992-39.99-39.992H120.104c-22.05 0-39.992 17.942-39.992 39.992a7.994 7.994 0 0 1-7.998 7.998 7.994 7.994 0 0 1-7.998-7.998c0-30.87 25.12-55.988 55.988-55.988h783.832c30.868 0 55.988 25.12 55.988 55.988a7.996 7.996 0 0 1-8 7.998z"
                            fill=""></path>
                        <path
                            d="M903.936 815.812H120.104c-31.392 0-55.988-24.59-55.988-55.988v-31.994a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998v31.994c0 22.432 17.566 39.99 39.992 39.99h783.832c22.432 0 39.99-17.558 39.99-39.99V728.08a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998v31.744c0 31.398-24.59 55.988-55.988 55.988zM360.054 607.98H184.09a7.994 7.994 0 0 1-7.998-8V344.036a7.994 7.994 0 0 1 7.998-7.998h175.962c4.422 0 8 3.576 8 7.998V599.98c0 4.422-3.576 8-7.998 8z m-167.966-15.996h159.966V352.034H192.088v239.95z"
                            fill=""></path>
                        <path
                            d="M312.064 575.986h-79.982c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.762-23.996 23.996-23.996h79.982c13.232 0 23.996 10.764 23.996 23.996 0 13.23-10.764 23.994-23.996 23.994z m-79.982-31.994a8.008 8.008 0 0 0-7.998 8 8.008 8.008 0 0 0 7.998 7.998h79.982c4.414 0 8-3.594 8-7.998 0-4.406-3.584-8-8-8h-79.982zM360.054 480.006H184.09a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h175.962c4.422 0 8 3.578 8 7.998a7.992 7.992 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M360.062 480.006a7.96 7.96 0 0 1-6.404-3.202l-41.594-55.45-41.592 55.45a7.974 7.974 0 0 1-11.194 1.602 7.986 7.986 0 0 1-1.602-11.194l47.99-63.986c3.016-4.032 9.78-4.032 12.794 0l47.99 63.986a7.986 7.986 0 0 1-1.6 11.194 7.96 7.96 0 0 1-4.788 1.6z"
                            fill=""></path>
                        <path
                            d="M216.076 480.006a7.982 7.982 0 0 1-4.992-1.748 8.014 8.014 0 0 1-1.25-11.248l31.994-39.992a8.01 8.01 0 0 1 6.248-3 8.02 8.02 0 0 1 6.25 3.008l23.746 29.698a7.994 7.994 0 0 1-1.258 11.24 8.006 8.006 0 0 1-11.24-1.258l-17.496-21.886-25.744 32.19a8.02 8.02 0 0 1-6.258 2.996zM232.082 416.02c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.762-23.996 23.996-23.996 13.232 0 23.994 10.764 23.994 23.996 0 13.23-10.764 23.994-23.994 23.994z m0-31.992a8.002 8.002 0 0 0-7.998 7.998 8.004 8.004 0 0 0 7.998 7.998 8.004 8.004 0 0 0 7.998-7.998 8.004 8.004 0 0 0-7.998-7.998zM264.074 512h-47.99a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h47.99a7.994 7.994 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998zM328.062 512h-23.996a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h23.996a7.994 7.994 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998zM248.078 575.986a7.994 7.994 0 0 1-7.998-7.998v-31.994a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998v31.994a7.992 7.992 0 0 1-7.998 7.998zM599.998 607.98H424.04c-4.42 0-8-3.578-8-8V344.036a7.994 7.994 0 0 1 8-7.998h175.958a7.994 7.994 0 0 1 7.998 7.998V599.98c0 4.422-3.578 8-7.998 8z m-167.958-15.996H592V352.034h-159.96v239.95z"
                            fill=""></path>
                        <path
                            d="M552.008 575.986h-79.976c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.764-23.996 23.996-23.996h79.976c13.232 0 23.994 10.764 23.994 23.996 0 13.23-10.762 23.994-23.994 23.994z m-79.976-31.994c-4.414 0-8 3.594-8 8a8.01 8.01 0 0 0 8 7.998h79.976a8.014 8.014 0 0 0 7.998-7.998c0-4.406-3.594-8-7.998-8h-79.976zM599.998 480.006H424.04c-4.42 0-8-3.576-8-7.998a7.994 7.994 0 0 1 8-7.998h175.958a7.994 7.994 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M599.998 480.006a7.994 7.994 0 0 1-6.406-3.202l-41.584-55.45-41.586 55.45a7.972 7.972 0 0 1-11.192 1.602 7.986 7.986 0 0 1-1.6-11.194l47.976-63.986c3.03-4.032 9.778-4.032 12.808 0l47.99 63.986a8.008 8.008 0 0 1-6.406 12.794z"
                            fill=""></path>
                        <path
                            d="M456.026 480.006a7.982 7.982 0 0 1-4.992-1.748 8.014 8.014 0 0 1-1.25-11.248l31.992-39.992a8.012 8.012 0 0 1 6.25-3 8.02 8.02 0 0 1 6.248 3.008l23.746 29.698a7.996 7.996 0 1 1-12.498 9.982l-17.496-21.886-25.746 32.19a8.006 8.006 0 0 1-6.254 2.996zM472.032 416.02c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.764-23.996 23.996-23.996s23.996 10.764 23.996 23.996c-0.002 13.23-10.766 23.994-23.996 23.994z m0-31.992c-4.414 0-8 3.584-8 7.998 0 4.412 3.586 7.998 8 7.998s7.998-3.586 7.998-7.998a8.004 8.004 0 0 0-7.998-7.998zM504.024 512h-47.99a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h47.99a7.994 7.994 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998zM568.004 512h-23.994c-4.422 0-8-3.578-8-7.998a7.994 7.994 0 0 1 8-7.998h23.994a7.992 7.992 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998zM488.028 575.986c-4.422 0-8-3.576-8-7.998v-31.994a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v31.994a7.994 7.994 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M840.134 607.98h-175.962a7.994 7.994 0 0 1-7.998-8V344.036a7.994 7.994 0 0 1 7.998-7.998h175.962c4.422 0 8 3.576 8 7.998V599.98c0 4.422-3.578 8-8 8z m-167.964-15.996h159.966V352.034h-159.966v239.95z"
                            fill=""></path>
                        <path
                            d="M792.146 575.986h-79.984c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.764-23.996 23.996-23.996h79.984c13.23 0 23.994 10.764 23.994 23.996 0 13.23-10.764 23.994-23.994 23.994z m-79.984-31.994a8.014 8.014 0 0 0-7.998 8 8.014 8.014 0 0 0 7.998 7.998h79.984a8.014 8.014 0 0 0 7.996-7.998c0-4.406-3.592-8-7.996-8h-79.984zM840.134 480.006h-175.962a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h175.962c4.422 0 8 3.578 8 7.998a7.994 7.994 0 0 1-8 7.998z"
                            fill=""></path>
                        <path
                            d="M840.134 480.006a7.99 7.99 0 0 1-6.404-3.202l-41.584-55.45-41.586 55.45a7.986 7.986 0 0 1-11.202 1.602 8.01 8.01 0 0 1-1.61-11.194l47.99-63.986c3.032-4.032 9.78-4.032 12.81 0l47.99 63.986a8.01 8.01 0 0 1-6.404 12.794z"
                            fill=""></path>
                        <path
                            d="M696.166 480.006a7.984 7.984 0 0 1-5-1.748 8.01 8.01 0 0 1-1.25-11.248l31.994-39.992a7.996 7.996 0 0 1 12.498 0.008l23.744 29.698a8 8 0 0 1-1.25 11.24 7.984 7.984 0 0 1-11.248-1.258l-17.496-21.886-25.744 32.19a8.002 8.002 0 0 1-6.248 2.996zM712.162 416.02c-13.232 0-23.996-10.764-23.996-23.994 0-13.232 10.764-23.996 23.996-23.996 13.23 0 23.994 10.764 23.994 23.996 0 13.23-10.764 23.994-23.994 23.994z m0-31.992a8.008 8.008 0 0 0-7.998 7.998c0 4.412 3.592 7.998 7.998 7.998s7.998-3.586 7.998-7.998a8.008 8.008 0 0 0-7.998-7.998zM744.154 512h-47.988c-4.422 0-8-3.578-8-7.998a7.994 7.994 0 0 1 8-7.998h47.988c4.422 0 8 3.576 8 7.998a7.994 7.994 0 0 1-8 7.998zM808.142 512h-23.996a7.994 7.994 0 0 1-7.998-7.998 7.994 7.994 0 0 1 7.998-7.998h23.996a7.994 7.994 0 0 1 7.998 7.998 7.994 7.994 0 0 1-7.998 7.998zM728.158 575.986a7.994 7.994 0 0 1-7.998-7.998v-31.994a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998v31.994a7.992 7.992 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M8.128 223.936a7.996 7.996 0 0 1-5.618-13.692l64.862-63.986a7.994 7.994 0 0 1 5.618-2.304H952.8c2.14 0 4.188 0.86 5.704 2.382l63.112 63.986a8.008 8.008 0 0 1-0.078 11.31c-3.138 3.102-8.186 3.07-11.326-0.078L949.46 159.948H76.27L13.744 221.63a7.954 7.954 0 0 1-5.616 2.306z"
                            fill=""></path>
                        <path
                            d="M32.122 287.922a39.986 39.986 0 0 1-30.088-13.654 8 8 0 0 1 0.758-11.288c3.296-2.904 8.35-2.576 11.288 0.758a23.974 23.974 0 0 0 18.042 8.188c13.232 0 23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c-0.002 22.05-17.944 39.992-39.994 39.992z"
                            fill=""></path>
                        <path
                            d="M96.108 287.922c-22.048 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.994 23.996 13.232 0 23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 22.05-17.94 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M160.096 287.922c-22.05 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.996 23.996s23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c-0.002 22.05-17.942 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M224.084 287.922c-22.05 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.996 23.996 13.23 0 23.994-10.764 23.994-23.996a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998-0.002 22.05-17.944 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M288.07 287.922c-22.05 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 13.232 10.762 23.996 23.996 23.996 13.232 0 23.994-10.764 23.994-23.996 0-4.42 3.578-7.998 8-7.998s8 3.578 8 7.998c-0.004 22.05-17.946 39.992-39.996 39.992z"
                            fill=""></path>
                        <path
                            d="M352.056 287.922c-22.05 0-39.992-17.942-39.992-39.992 0-4.42 3.578-7.998 8-7.998s8 3.578 8 7.998c0 13.232 10.762 23.996 23.994 23.996s23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c-0.004 22.05-17.946 39.992-39.996 39.992z"
                            fill=""></path>
                        <path
                            d="M416.042 287.922c-22.048 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.994 23.996 13.232 0 23.996-10.764 23.996-23.996 0-4.42 3.578-7.998 7.998-7.998s7.998 3.578 7.998 7.998c0 22.05-17.942 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M480.03 287.922c-22.05 0-39.992-17.942-39.992-39.992 0-4.42 3.578-7.998 7.998-7.998s7.998 3.578 7.998 7.998c0 13.232 10.764 23.996 23.996 23.996 13.224 0 23.988-10.764 23.988-23.996a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998-0.002 22.05-17.936 39.992-39.986 39.992z"
                            fill=""></path>
                        <path
                            d="M544.01 287.922c-22.05 0-39.994-17.942-39.994-39.992a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 13.232 10.764 23.996 23.996 23.996 13.23 0 23.994-10.764 23.994-23.996a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 22.05-17.934 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M607.996 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 13.232 10.762 23.996 23.994 23.996s23.994-10.764 23.994-23.996a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 22.05-17.936 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M671.982 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.994 23.996 13.232 0 23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 22.05-17.932 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M735.97 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.996 23.996 13.23 0 23.994-10.764 23.994-23.996 0-4.42 3.576-7.998 7.998-7.998s7.998 3.578 7.998 7.998c0 22.05-17.932 39.992-39.99 39.992z"
                            fill=""></path>
                        <path
                            d="M799.956 287.922c-22.056 0-39.99-17.942-39.99-39.992 0-4.42 3.576-7.998 7.998-7.998s7.998 3.578 7.998 7.998c0 13.232 10.764 23.996 23.994 23.996 13.232 0 23.996-10.764 23.996-23.996a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 22.05-17.934 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M863.944 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.996 23.996 13.23 0 23.994-10.764 23.994-23.996a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 22.05-17.934 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M927.93 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 7.998-7.998c4.422 0 8 3.578 8 7.998 0 13.232 10.762 23.996 23.994 23.996s23.994-10.764 23.994-23.996a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 22.05-17.934 39.992-39.992 39.992z"
                            fill=""></path>
                        <path
                            d="M991.916 287.922c-22.058 0-39.992-17.942-39.992-39.992a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998c0 13.232 10.764 23.996 23.994 23.996a24.04 24.04 0 0 0 18.028-8.156c2.906-3.318 7.968-3.63 11.294-0.704a8.008 8.008 0 0 1 0.704 11.294 40.1 40.1 0 0 1-30.026 13.562z"
                            fill=""></path>
                        <path
                            d="M8.128 277.002c-4.422 0-8-3.578-8-7.998v-53.068a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v53.068a7.994 7.994 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M984.792 223.936H40.996a7.994 7.994 0 0 1-7.998-8 7.994 7.994 0 0 1 7.998-7.998h943.798a7.994 7.994 0 0 1 7.998 7.998c0 4.422-3.58 8-8 8z"
                            fill=""></path>
                        <path
                            d="M1015.912 277.064c-4.422 0-8-3.578-8-7.998v-53.13a7.994 7.994 0 0 1 8-7.998 7.994 7.994 0 0 1 7.998 7.998v53.13a7.994 7.994 0 0 1-7.998 7.998z"
                            fill=""></path>
                        <path
                            d="M140.124 207.938a7.936 7.936 0 0 1-4.796-1.61 7.988 7.988 0 0 1-1.586-11.192L157.8 163.142a7.974 7.974 0 0 1 11.192-1.586 7.99 7.99 0 0 1 1.586 11.194L146.52 204.744a7.976 7.976 0 0 1-6.396 3.194z"
                            fill=""></path>
                        <path
                            d="M202.102 207.938a7.89 7.89 0 0 1-4.242-1.226 7.986 7.986 0 0 1-2.522-11.02l20.066-31.994a7.982 7.982 0 0 1 11.02-2.524 7.988 7.988 0 0 1 2.522 11.022l-20.066 31.994a7.974 7.974 0 0 1-6.778 3.748z"
                            fill=""></path>
                        <path
                            d="M264.066 208a7.95 7.95 0 0 1-3.578-0.852 7.986 7.986 0 0 1-3.562-10.73l15.998-31.87a7.988 7.988 0 0 1 10.732-3.562 7.99 7.99 0 0 1 3.562 10.732l-15.996 31.87a8.008 8.008 0 0 1-7.156 4.412z"
                            fill=""></path>
                        <path
                            d="M326.054 207.938c-0.938 0-1.882-0.164-2.812-0.508a8.008 8.008 0 0 1-4.678-10.302l12.004-31.992c1.554-4.148 6.164-6.204 10.302-4.68a8.01 8.01 0 0 1 4.68 10.302l-12.006 31.994a8 8 0 0 1-7.49 5.186z"
                            fill=""></path>
                        <path
                            d="M388.056 207.938c-0.64 0-1.296-0.078-1.944-0.242a7.986 7.986 0 0 1-5.82-9.694l7.998-31.992a7.964 7.964 0 0 1 9.694-5.82 7.986 7.986 0 0 1 5.82 9.694l-8 31.994a7.984 7.984 0 0 1-7.748 6.06z"
                            fill=""></path>
                        <path
                            d="M450.036 207.938a8 8 0 0 1-7.944-8.99l4-31.992a7.998 7.998 0 0 1 15.872 1.982l-4 31.994a8.004 8.004 0 0 1-7.928 7.006z"
                            fill=""></path>
                        <path
                            d="M512.014 207.938a7.994 7.994 0 0 1-7.998-7.998V167.948c0-4.422 3.578-8 7.998-8 4.422 0 8 3.578 8 8V199.94a7.992 7.992 0 0 1-8 7.998z"
                            fill=""></path>
                        <path
                            d="M78.144 207.938a8 8 0 0 1-6.014-13.27l28.056-31.994a8.016 8.016 0 0 1 11.288-0.742 8.002 8.002 0 0 1 0.742 11.288L84.16 205.214a7.996 7.996 0 0 1-6.016 2.724z"
                            fill=""></path>
                        <path
                            d="M883.94 207.954a8 8 0 0 1-6.39-3.186L853.46 172.758a7.998 7.998 0 1 1 12.78-9.624l24.088 32.01a7.996 7.996 0 0 1-6.388 12.81z"
                            fill=""></path>
                        <path
                            d="M821.952 207.954a8.012 8.012 0 0 1-6.78-3.748l-20.09-32.01a7.99 7.99 0 0 1 2.532-11.022c3.716-2.318 8.67-1.226 11.03 2.524l20.09 32.01a7.99 7.99 0 0 1-2.532 11.02 7.994 7.994 0 0 1-4.25 1.226z"
                            fill=""></path>
                        <path
                            d="M759.964 207.954a7.964 7.964 0 0 1-7.138-4.404l-16.092-32.01a7.994 7.994 0 0 1 3.546-10.74 7.98 7.98 0 0 1 10.732 3.554l16.09 32.01a7.99 7.99 0 0 1-7.138 11.59z"
                            fill=""></path>
                        <path
                            d="M697.978 207.954a7.984 7.984 0 0 1-7.484-5.194l-11.996-32.01a7.994 7.994 0 0 1 4.67-10.294c4.108-1.516 8.748 0.532 10.294 4.688l11.996 32.008a7.99 7.99 0 0 1-7.48 10.802z"
                            fill=""></path>
                        <path
                            d="M635.99 207.954a7.996 7.996 0 0 1-7.764-6.06l-7.998-32.01a8.01 8.01 0 0 1 5.826-9.694c4.294-1.118 8.624 1.532 9.702 5.82l7.998 32.008a8.01 8.01 0 0 1-7.764 9.936z"
                            fill=""></path>
                        <path
                            d="M573.986 207.954a7.99 7.99 0 0 1-7.918-7.006l-4-32.01a8 8 0 0 1 6.952-8.928c4.078-0.554 8.374 2.546 8.92 6.946l4 32.008a8 8 0 0 1-7.954 8.99z"
                            fill=""></path>
                        <path
                            d="M945.926 207.954a7.98 7.98 0 0 1-6.014-2.724L911.824 173.22a8.014 8.014 0 0 1 0.734-11.288 8.02 8.02 0 0 1 11.294 0.742l28.086 32.01a8.014 8.014 0 0 1-0.734 11.288 8.006 8.006 0 0 1-5.278 1.982z"
                            fill=""></path>
                    </g>
                    <linearGradient id="gradientColor">
                        <stop offset="5%" stopColor="#7eaaff"></stop>
                        <stop offset="95%" stopColor="#ff48fb"></stop>
                    </linearGradient>
                </svg>,
            link: "/services/marketplace",
            sub: [
                {
                    subtitle: "A+ Content",
                    sub: [
                        {
                            subtitle: "Enhance Product Descriptions"
                        },
                        {
                            subtitle: "Visual Rich Content"
                        },
                        {
                            subtitle: "Highlight Key Features"
                        }
                    ]
                },
                {
                    subtitle: "SEO",
                    sub: [
                        {
                            subtitle: "Optimize Product Listings"
                        },
                        {
                            subtitle: "Keyword Research Strategies"
                        },
                        {
                            subtitle: "Increase Search Visibility"
                        },
                        {
                            subtitle: "Drive Organic Traffic"
                        }
                    ]
                },
                {
                    subtitle: "Brand Registry",
                    sub: [
                        {
                            subtitle: "Protect Your Brand Identity"
                        },
                        {
                            subtitle: "Access Enhanced Marketing Tools"
                        },
                        {
                            subtitle: "Build Customer Trust"
                        }
                    ]
                },
                {
                    subtitle: "Store Page Design",
                    sub: [
                        {
                            subtitle: "Create Engaging Storefronts"
                        },
                        {
                            subtitle: "Customize Brand Look and Feel"
                        },
                        {
                            subtitle: "Improve User Experience"
                        }
                    ]
                },
                {
                    subtitle: "Social Media Management",
                    sub: [
                        {
                            subtitle: "Build Brand Presence"
                        },
                        {
                            subtitle: "Engage with Your Audience"
                        },
                        {
                            subtitle: "Run Effective Ad Campaigns"
                        }
                    ]
                },
                {
                    subtitle: "Logistic Management",
                    sub: [
                        {
                            subtitle: "Optimize Shipping Processes"
                        },
                        {
                            subtitle: "Inventory Management"
                        },
                        {
                            subtitle: "Efficient Order Fulfillment"
                        }
                    ]
                },
            ]

        },
        {
            title: "Creative Solutions",
            description:"We provide creative solutions to clients around the world, creating things that get attention and meaningful.",
            svg:
                <svg width="200" height="200" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                     id="IconChangeColor">
                    <path
                        d="M24 0C10.745 0 0 10.745 0 24C0 37.255 10.745 48 24 48C37.255 48 48 37.255 48 24C48 10.745 37.255 0 24 0ZM24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24C44 35.046 35.046 44 24 44Z"
                        fill="#ffffff"></path>
                    <path
                        d="M24 8C13.178 8 4 17.178 4 28C4 38.822 13.178 48 24 48C34.822 48 44 38.822 44 28C44 17.178 34.822 8 24 8ZM24 46C14.059 46 6 37.941 6 28C6 18.059 14.059 10 24 10C33.941 10 42 18.059 42 28C42 37.941 33.941 46 24 46Z"
                        fill="#ffffff"></path>
                    <path
                        d="M24 12C15.163 12 8 19.163 8 28C8 36.837 15.163 44 24 44C32.837 44 40 36.837 40 28C40 19.163 32.837 12 24 12ZM24 42C16.268 42 10 35.732 10 28C10 20.268 16.268 14 24 14C31.732 14 38 20.268 38 28C38 35.732 31.732 42 24 42Z"
                        fill="#ffffff"></path>
                    <path
                        d="M24 16C17.373 16 12 21.373 12 28C12 34.627 17.373 40 24 40C30.627 40 36 34.627 36 28C36 21.373 30.627 16 24 16ZM24 38C18.486 38 14 33.514 14 28C14 22.486 18.486 18 24 18C29.514 18 34 22.486 34 28C34 33.514 29.514 38 24 38Z"
                        fill="#ffffff"></path>
                    <path
                        d="M24 20C19.582 20 16 23.582 16 28C16 32.418 19.582 36 24 36C28.418 36 32 32.418 32 28C32 23.582 28.418 20 24 20ZM24 34C20.686 34 18 31.314 18 28C18 24.686 20.686 22 24 22C27.314 22 30 24.686 30 28C30 31.314 27.314 34 24 34Z"
                        fill="#ffffff"></path>
                    <path
                        d="M24 24C21.791 24 20 25.791 20 28C20 30.209 21.791 32 24 32C26.209 32 28 30.209 28 28C28 25.791 26.209 24 24 24ZM24 30C22.897 30 22 29.103 22 28C22 26.897 22.897 26 24 26C25.103 26 26 26.897 26 28C26 29.103 25.103 30 24 30Z"
                        fill="#ffffff"></path>
                    <linearGradient id="gradientColor">
                        <stop offset="5%" stopColor="#7eaaff"></stop>
                        <stop offset="95%" stopColor="#ff48fb"></stop>
                    </linearGradient>
                </svg>,
            link: "/services/solutions",
            sub: [
                {
                    subtitle: "Walmart Marketplace",
                    sub: [
                        {
                            subtitle: "Product Listing Optimization"
                        },
                        {
                            subtitle: "Inventory Management"
                        },
                        {
                            subtitle: "Order Fulfillment Strategies"
                        }
                    ]
                },
                {
                    subtitle: "Etsy Marketplace",
                    sub: [
                        {
                            subtitle: "Unique Product Showcasing"
                        },
                        {
                            subtitle: "Customer Engagement Tactics"
                        },
                        {
                            subtitle: "Shop Customization"
                        }
                    ]
                },
                {
                    subtitle: "Trendyol Marketplace",
                    sub: [
                        {
                            subtitle: "Strategic Product Positioning"
                        },
                        {
                            subtitle: "Promotional Campaigns"
                        },
                        {
                            subtitle: "Performance Analytics"
                        }
                    ]
                },
                {
                    subtitle: "Shopify",
                    sub: [
                        {
                            subtitle: "Store Design and Branding"
                        },
                        {
                            subtitle: "Payment and Checkout Optimization"
                        },
                        {
                            subtitle: "App Integration"
                        }
                    ]
                },
                {
                    subtitle: "Google ADS",
                    sub: [
                        {
                            subtitle: "Search Ads"
                        },
                        {
                            subtitle: "Display Ads"
                        },
                        {
                            subtitle: "Video Ads"
                        }
                    ]
                },
                {
                    subtitle: "Meta ADS",
                    sub: [
                        {
                            subtitle: "Meta Tag Optimization"
                        },
                        {
                            subtitle: "Keyword Analysis"
                        },
                        {
                            subtitle: "Ad Performance Monitoring"
                        }
                    ]
                },
                {
                    subtitle: "Off-Amazon Marketing",
                    sub: [
                        {
                            subtitle: "Multi-Channel Advertising"
                        },
                        {
                            subtitle: "Social Media Campaigns"
                        },
                        {
                            subtitle: "Email Marketing"
                        }
                    ]
                },
                {
                    subtitle: "Website",
                    sub: [
                        {
                            subtitle: "Shopify"
                        },
                        {
                            subtitle: "WordPress"
                        },
                        {
                            subtitle: "Custom (React, HTML, CSS, JS)"
                        }
                    ]
                }
            ]

        }
        ,
    ]


    const [selectedTab, setSelectedTab] = useState(0);
    //
    // const handleTabClick = (index) => {
    //     setSelectedTab(index);
    // };

    const getGridColumns = (numCards) => {
        switch (numCards) {
            case 8:
                return 'xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2';
            case 7:
                return 'xl:grid-cols-4 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2';
            case 6:
                return 'xl:grid-cols-4 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2';
            case 5:
                return 'xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 ';
            case 4:
                return 'lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2';
            case 3:
                return 'md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2';
            case 2:
                return '!flex sm:flex-no-wrap flex-wrap gap-2 sm:!justify-start';
            default:
                return 'lg:grid-cols-';
        }
    };

    const scrollOnLoad = useRef(true); // Ref'i kullanarak ilk yüklemede scroll'u kontrol etmek için
    const selectedTabRef = useRef(selectedTab);
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            const offset = 125; // İstediğiniz kadar bir üst boşluk ekleyebilirsiniz
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;

            window.scrollTo({
                top: topPosition,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        // Sayfa yüklendiğinde belirli bir bölüme kaydırma
        if (window.location.hash && scrollOnLoad.current) {
            const sectionId = window.location.hash.substring(1);
            scrollToSection(sectionId);
            scrollOnLoad.current = false; // İlk yüklemede bir daha kontrol etme
        }
    }, []); // Sadece sayfa yüklendiğinde çalışacak

    useEffect(() => {
        // Her seçimde belirli bir bölüme kaydırma
        if (selectedTab !== selectedTabRef.current) {
            selectedTabRef.current = selectedTab;
            const sectionId = serviceInfosArr[selectedTab].link;
            scrollToSection(sectionId);
        }
    }, [selectedTab]); // selectedTab değiştiğinde çalışacak

    const handleTabClick = (index, sectionId) => {
        setSelectedTab(index);
        scrollToSection(sectionId);
    };

    return (
        <MainLayout>
            <div className="bg-mainColor">
                <section className="py-6">
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 1}}

                        className="container mx-auto flex items-center justify-around w-full lg:h-[70vh] h-full gap-4 md:px-6">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col items-center justify-center bg-light">
                                <Typography className="md:px-8 text-center text-6xl gradientPattern font-montserrat" color="white"
                                            variant="h1">SERVICES</Typography>

                            </div>
                            <div className="flex flex-col justify-center items-center gap-6">
                                <Typography className="px-8 text-center font-montserrat" color="white" variant="lead">
                                    We offer full-service social marketing, creative, media and technical
                                    disciplines,
                                    all grounded in a unique understanding of online culture and social behaviors.
                                </Typography>
                                <ScoolButton
                                    link={'contact'}
                                    buttonText={'Contact Us'}
                                    buttonClassName="backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
                                    setOffset={-76}
                                />
                            </div>
                        </div>
                        <img src={servicesBanner} alt="" className="lg:block hidden object-contain w-2/3 h-full"/>
                    </motion.div>
                </section>



                <div>
                    {/* TAB HEADERS */}
                    <div className="container mx-auto grid lg:grid-cols-3 items-center justify-center w-full h-full gap-4 px-4 mb-12">
                        {serviceInfosArr.map((item, index) => (
                            <motion.div
                                key={index}
                                className={`flex items-center justify-center ${selectedTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index, index)}
                                initial="hidden"
                                animate="visible"
                            >
                                <ServicesCard title={item.title} description={item.description} svg={item.svg}/>
                            </motion.div>
                        ))}
                    </div>

                    {/* TAB BODY */}
                    {serviceInfosArr.map((item, index) => (
                        <motion.div
                            key={index}
                            initial="hidden"
                            animate={selectedTab === index ? 'visible' : 'hidden'}
                        >
                            {selectedTab === index && (
                                <section className="tabBodyTitle">
                                    <Typography id={item.link} className="container mx-auto px-5 sm:text-3xl text-2xl  text-center font-montserrat" color={"white"}>
                                        {item.title}
                                    </Typography>
                                    <div className={`container mx-auto p-4 justify-center items-center grid ${getGridColumns(item.sub?.length || 0)} gap-5`}>
                                        {item.sub?.map((subItem, subIndex) => (
                                            <div className="flex items-center">
                                                <motion.div
                                                    key={subIndex}
                                                    initial="hidden"
                                                    animate="visible"
                                                    className="card"
                                                >
                                                    <div className="card2 flex flex-col justify-center pt-6 px-2 relative">
                                                        <Link to={item.link} className="text-xl font-semibold text-center italic w-full py-1 text-white shadowText absolute top-2 left-1/2 transform -translate-x-1/2">
                                                            {subItem?.subtitle}
                                                        </Link>
                                                        {subItem?.sub?.map((nestedItem, nestedIndex) => (
                                                            <li className={`list-none text-white shadowText text-sm ${nestedIndex === 0 ? 'pt-6' : ''}`} key={nestedIndex}>○ {nestedItem?.subtitle}</li>
                                                        ))}
                                                    </div>
                                                </motion.div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            )}
                        </motion.div>
                    ))}
                </div>


            </div>
        </MainLayout>

    )
}
