import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import walmartBanner from "../../../Assets/Images/story/moneyIncome3.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersWalmart from "../../../Assets/Images/banners/Walmart Market Management.webp";

export default function WalmartMarketManagement() {
    const bannerHeader = "Achieve Walmart Marketplace Success";
    const bannerTxt = "Partner with us for comprehensive Walmart Marketplace services. From optimizing your account to boosting visibility and driving sales, we're committed to ensuring your brand thrives on Walmart Marketplace.";
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersWalmart}/>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Account Management
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Advertising Excellence
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Elevate your product visibility on the Walmart Marketplace through meticulously
                                    designed and managed ad campaigns. Our team specializes in driving targeted traffic,
                                    enhancing both visibility and sales.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Precision Catalog Management Solutions
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Ensure your product listings are accurately portrayed to maximize conversion rates.
                                    Our catalog management services are geared towards optimization, aligning your
                                    products for maximum impact on the Walmart Marketplace.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Advanced SEO Strategies for Enhanced Visibility
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Increase the discoverability of your products through adept SEO strategies. We
                                    optimize product listings to harmonize with Walmart's search algorithms, ensuring
                                    heightened visibility among potential customers.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Profit Optimization through Strategic Pricing
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Set competitive prices while maximizing profitability through strategic analysis.
                                    Our team conducts comprehensive market research to assist you in determining the
                                    most optimal pricing strategy for your products.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    In-Depth Market Analysis for Competitive Insight
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay ahead in the competitive landscape with our in-depth market analysis. We
                                    closely monitor industry trends, competitor activities, and customer behavior to
                                    provide valuable insights that inform your business strategy on the Walmart
                                    Marketplace.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Walmart Marketplace?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Walmart Marketplace?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Expertise in Walmart Marketplace: Our team has proven success in navigating and
                                        optimizing Walmart Marketplace accounts.

                                    </li>
                                    <li>
                                        Comprehensive Solutions: From advertising to quantity management, we offer a
                                        holistic approach to ensure your success on the platform.
                                    </li>
                                    <li>
                                        Customized Strategies: Recognizing the uniqueness of each brand, we tailor our
                                        services to align with your specific goals and brand identity.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Elevate your brand on the Walmart Marketplace with Ra Digital. Contact us today to discover how
                        our comprehensive services can unlock your account's full potential, drive increased sales, and
                        cultivate sustainable success on Walmart."
                    </Typography>
                </div>
            </section>
        </div>


    )
}