import React from 'react';
import './index.css';
import {
    BrowserRouter, Route, Routes,
} from "react-router-dom";
import HomePage from "./Views/Home";
import NotFound from "./Views/404/NotFound";
import {Services} from "./Views/Services/services";
import {ServicesAdvertising} from "./Views/Services/ServicesAdvertising";
import {ServicesMarketPlace} from "./Views/Services/ServicesMarketPlace";
import {ServicesSolution} from "./Views/Services/ServicesSolution";
import {ServiceDetail} from "./Views/Services/ServiceDetail";
import AboutPage from "./Views/About/About";
import {Resources} from "./Views/Resources/Resources";
import BlogPost from "./Views/Resources/Detail/blogPost";
import FAQ from "./Views/FAQ/faq";
import ScrollToTop from "./Components/Extra/useScrollTop";
import {References} from "./Views/Resources/References";
import FormSuccess from "./Views/FormSucccess";
import {TermsAndConditions} from "./Views/TermsAndConditions";
import {Contact} from "./Views/Contact/contact";

function App() {
    return (
        <BrowserRouter basename="/">
            <ScrollToTop/>

            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="*" element={<NotFound/>}/> {/* 404 */}

                <Route path="/services" element={<Services/>}/> {/* Services */}
                <Route path="/services/avertising" element={<ServicesAdvertising/>}/> {/* Services */}
                <Route path="/services/marketplace" element={<ServicesMarketPlace/>}/> {/* Services */}
                <Route path="/services/solutions" element={<ServicesSolution/>}/> {/* Services */}

                <Route path="/about" element={<AboutPage/>}/> {/* About */}
                <Route path="/resources/:resource_name" element={<Resources/>}/> {/* Resources Under Titles*/}
                <Route path="/resources/blog/:id" element={<BlogPost/>} /> {/* Blog Post */}
                <Route path="/resources/references" element={<References/>}/> {/* Resources */}

                <Route path="/faq" element={<FAQ/>}/> {/* FAQ */}
                <Route path="/form-success" element={<FormSuccess/>}/> {/* FormSuccess */}
                <Route path="/terms&conditions" element={<TermsAndConditions/>}/> {/* TermsAndConditions */}

                <Route path="/services/:service_name" element={<ServiceDetail/>}/> {/* Service Detail */}


                <Route path="/contact" element={<Contact/>}/> {/* CONTACT */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
