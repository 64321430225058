import React from 'react';
import MainLayout from '../../Layout/MainLayout';
import {motion} from 'framer-motion';
import experts from '../../Assets/Images/story/experts.png';
import team from '../../Assets/Images/story/team.svg';
import {BiLogoAmazon} from "react-icons/bi";
import {MdCampaign} from "react-icons/md";
import {SiManageiq} from "react-icons/si";

export default function AboutPage() {
    return (
        <MainLayout>
            {/* Hero Section */}
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="py-6"
            >
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-row gap-4">
                        <div className="w-full px-4 mb-10 lg:w-2/3 lg:mb-0 lg:flex lg:items-center">
                            <div className="lg:max-w-md">
                                <div className="px-4 pl-4 mb-6 border-l-4 border-[#503DFFFF]">
                                    <span
                                        className="text-sm text-gray-500 uppercase dark:text-gray-400">Who we are?</span>
                                    <h1 className="mt-2 text-3xl font-black text-gray-300 md:text-5xl dark:text-gray-300">
                                        About Ra Digital
                                    </h1>
                                </div>
                                <p className="px-4 mb-3 text-base leading-7 text-gray-400 dark:text-gray-400">
                                    At Ra Digital, where innovation meets impact in the realm of advertising. We're not
                                    just an advertising agency; we are your dedicated partner on the journey to business
                                    growth and success.
                                </p>
                            </div>
                        </div>
                        <div className="md:relative absolute md:opacity-100 opacity-20  w-full px-4 mb-3 lg:w-1/3 lg:mb-0 flex items-center justify-center">
                            <img
                                src={team}
                                alt=""
                                className="object-cover w-full h-full rounded mx-auto transform transition-transform md:hover:scale-105 duration-300"
                            />
                        </div>
                    </div>
                </div>
            </motion.section>

            {/* Other sections */}
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="max-w-6xl mx-auto px-4 mx-auto text-center">
                {/* Section Title */}
                <h2 className="text-2xl font-bold text-gray-300 mb-3 lg:text-5xl dark:text-gray-400">
                    Our Story
                </h2>

                {/* Section Description */}
                <p className="pb-10 mb-8 text-lg text-gray-400 border-b border-gray-200 dark:border-gray-600 dark:text-gray-400 md:text-xl">
                    Welcome to Ra Digital, where innovation meets impact in the realm of advertising. At radigital.io,
                    we're not just an advertising agency; we are your dedicated partner on the journey to business
                    growth and success.
                </p>

                {/* More About Ra Digital */}
                <div className="mx-auto md:max-w-6xl mx-auto">
                    {/* Paragraph */}
                    <p className="pb-10 mb-8 text-lg text-gray-400 border-b border-gray-200 dark:border-gray-600 dark:text-gray-400 md:text-xl">
                        At Ra Digital, we understand that your success is our success. We are committed to providing
                        comprehensive assistance in managing both your products and advertising campaigns. Our approach
                        is centered around aligning our targets with your business objectives, striving to achieve
                        optimal growth and profitability rates.
                    </p>

                    {/* Subtitle */}
                    <h2 className="mb-4 text-2xl font-semibold text-gray-400 md:text-3xl dark:text-gray-300">
                        More Than Just Promotion
                    </h2>
                    {/* Subtitle Description */}
                    <p className="mb-4 text-base text-gray-400 md:text-lg dark:text-gray-400">
                        We don't see advertising as a mere promotion; we view it as a meticulously reported and
                        organized endeavor, much like the operation of our own store. Our team of graphic designers
                        meticulously scrutinizes the products in your portfolio, offering valuable insights on product
                        images. Simultaneously, our team of engineers and statisticians conducts a thorough analysis of
                        your advertisements to determine the most suitable campaign types and bid strategies tailored to
                        your specific needs.
                    </p>

                    <div className="flex items-center justify-center py-6">
                        <div className="px-4 py-6 mx-auto max-w-7xl">
                            <div className="max-w-xl mx-auto">
                                <div className="text-center ">
                                    <div className="relative flex flex-col items-center ">
                                        <div
                                            className="absolute hidden md:block -top-14 left-0 text-[120px] text-gray-100 font-bold opacity-10"
                                        >
                                            FEATURES
                                        </div>
                                        <h1 className="text-5xl font-bold gradientPattern"> Our Features </h1>
                                        <div className="flex w-28 mt-1 mb-10 overflow-hidden rounded bg-gradient-to-r from-[#929afe] via-[#b387f6] to-[#de67f5] h-2"></div>
                                    </div>
                                    <p className="mb-16 text-base text-center text-gray-500">
                                        Ra Digital is not just an advertising agency; we're your dedicated partner in business growth.
                                        Our creative team crafts compelling campaigns, leveraging innovative strategies and cutting-edge
                                        tech. We measure success by your milestones, ensuring your brand stands out. Partner with us for a
                                        collaborative journey, turning challenges into triumphs.
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-x-4 gap-y-6 lg:gap-x-8 lg:gap-y-8 md:grid-cols-2 lg:grid-cols-3">
                                <div className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg">
                                    <div className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <BiLogoAmazon className="h-8 w-8 text-white" />
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Amazon Advertising Mastery
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Harness the power of our Amazon Advertising expertise. We specialize in crafting strategic
                                        campaigns tailored to the Amazon platform, ensuring your products shine amidst the competition.
                                        From sponsored products to display ads, we optimize every aspect to maximize your ROI.
                                    </p>
                                </div>
                                <div className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg"
                                >
                                    <div className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <MdCampaign className="h-8 w-8 text-white" />
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Multi-Channel Campaign Management
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Expand your reach across various platforms with our multi-channel campaign management. Whether
                                        it's Google Ads, Meta Ads, or other major e-commerce platforms, we orchestrate cohesive advertising
                                        strategies. Reach your target audience wherever they are and drive engagement on a global scale.
                                    </p>
                                </div>
                                <div className="w-full p-8 mb-5 text-center transition-all bg-[rgba(5,2,19,0.76)] rounded shadow dark:bg-gray-700 hover:shadow-lg"
                                >
                                    <div className="inline-block p-4 mb-6 -mt-16 bg-[rgba(242,248,249,0.4)] rounded-full">
                                        <SiManageiq className="h-8 w-8 text-white" />
                                    </div>
                                    <h3 className="mb-4 text-2xl font-semibold text-slate-200">
                                        Comprehensive Market Management Suite
                                    </h3>
                                    <p className="text-base text-slate-400">
                                        Elevate your online presence with our comprehensive market management suite. From Walmart and Etsy
                                        to Shopify, we provide end-to-end solutions. Our services include A-Content creation, brand
                                        registry support, social media management, and website optimization. Experience seamless integration
                                        and management across diverse marketplaces.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Another Section */}
                    <h2 className="my-4 text-2xl font-semibold text-gray-300 dark:text-gray-300 md:text-3xl">
                        Dedicated to Your Success
                    </h2>

                    {/* Another Section Description */}
                    <p className="mb-10 text-base text-gray-400 dark:text-gray-400 md:text-lg">
                        At Ra Digital, we're more than just a team; we're an extended family dedicated to your success.
                        Your triumphs are our triumphs, and we're here to support you every step of the way.
                    </p>


                    {/* Animated Quote */}
                    <div className="p-6 mb-4 bg-[#33415577] rounded-xl hover:animate-pulse transition duration-500">
                        <p className="mb-4 text-xl leading-tight text-slate-200 dark:text-gray-300 md:text-2xl italic">
                            "We're more than just a team; we're an extended family dedicated to your success."
                        </p>
                    </div>
                </div>
            </motion.section>

        </MainLayout>
    );
}
