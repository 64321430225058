import React from 'react';
import MainLayout from "../Layout/MainLayout";
import {Typography} from "@material-tailwind/react";
import {CustomMarque} from "../Components/Extra/Marque";
import {ScoolButton} from "../Components/Buttons/ScoolButton";
import demo from "../Assets/Images/demo1.mp4";
import {LinkButton} from "../Components/Buttons/CustomLinkButton";
import {motion} from "framer-motion";
import bannersHomePage from "../Assets/Images/banners/Anasayfa Transparent (2).webp";
export default function HomePage() {
    return (
        <MainLayout>
            {/*
            <section>
                <ShuffleHero/>
            </section>
            */}

            <section className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6 max-w-6xl mx-auto py-12">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                >
                    {/*<span className="block mb-4 text-xs md:text-sm text-indigo-500 font-medium">*/}
                    {/*  Better every day*/}
                    {/*</span>*/}
                    <h3 className="text-4xl md:text-6xl font-semibold gradientPattern">
                        {/*We’re the Growth Engine for Your Brand.*/}
                        We help brands grow their business on Amazon.

                    </h3>
                    <p className="text-base md:text-lg text-textWhite my-4 md:my-6 shadowText">
                        We are a team of Amazon experts who have been working with brands to grow their business on Amazon.
                        We have helped hundreds of brands grow their business on Amazon and we can help you too.
                    </p>
                    <ScoolButton
                        link={'contact'}
                        buttonText={'Free PPC Audit'}
                        buttonClassName="backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
                        setOffset={-76}
                    />

                </motion.div>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="bg-transparent">
                    <img src={bannersHomePage} alt="" className="rounded-lg"/>
                </motion.div>

                {/*<div className="bg-mainColor z-10">*/}
                {/*    <motion.video*/}
                {/*        initial={{opacity: 0, y: 20}}*/}
                {/*        animate={{opacity: 1, y: 0}}*/}
                {/*        transition={{duration: 0.6}}*/}
                {/*        className="h-max w-full rounded-lg" controls  >*/}
                {/*        <source src={demo} type="video/mp4" />*/}
                {/*        Your browser does not support the video tag.*/}
                {/*    </motion.video>*/}
                {/*</div>*/}
            </section>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="text-textWhiteHead font-montserrat">Achievements with Long-Term Goals</Typography>
                        <Typography color="white" variant="lead" className="text-textWhite font-montserrat">We excel at enhancing business results for your Amazon
                            brand
                            through a well-established strategy encompassing digital marketing, PPC management, creative
                            services, customer experience, Google Ads, Meta Ads, and Affiliate Marketing. Our approach
                            is
                            fortified by cutting-edge technology and a team of brilliant marketing experts from across
                            the
                            world.</Typography>
                    </div>
                </motion.div>
                <CustomMarque/>
                <div className="container mx-auto flex flex-col justify-center items-center gap-3 md:w-2/3">
                    {/*<Button color="black" variant="outlined" ripple="light" className="md:w-1/3">*/}
                    {/*    Learn More*/}
                    {/*</Button>*/}
                    <LinkButton buttonText={"Learn More"} link={"/services"} setOffset={-75} buttonClassName={"backgroundGradient"}/>
                </div>
            </section>
            <section className="bg-mainColor py-10">
                <div className="container mx-auto flex flex-col justify-center items-center gap-3 md:w-2/3 sm:px-0 px-3">
                    <Typography color="white" variant="h3" className="text-center">Empower Your Digital Marketing Strategy with Our Dynamic Expertise</Typography>
                    <Typography color="white" variant="text" className="tracking-wider">
                        In today's rapidly evolving digital marketing landscape, AI tools have taken the lead. Yet, they
                        often function like vending machines, delivering what you request. However, the dynamics of
                        marketing and marketplace management shift constantly. It's crucial to adapt to the
                        ever-changing needs of customers and the market. Take the initiative now to navigate these
                        dynamic changes with our seasoned and proficient team of professionals!
                    </Typography>
                    <div className="grid grid-cols-2 nbXs:grid-cols-1 py-4 gap-12 tracking-wide">
                            <Typography color="white" variant="text">
                                ○ Utilize our growth engine solutions to extend your brand's presence and boost overall
                                sales,
                                both within and outside of the Amazon ecosystem.
                            </Typography>

                        <Typography color="white" variant="text">
                            ○ We foster mutual trust and respect between our teams, aligning our values throughout our
                            organization to ensure a strong partnership
                        </Typography>
                        <Typography color="white" variant="text">
                            ○ Our data-first approach is a game-changer. Our team rigorously test and validate your data
                            before crafting your strategy.
                        </Typography>
                        <Typography color="white" variant="text">
                            ○ Enjoy seamless communication with our dedicated channel experts, providing you with
                            unrestricted access to our team of specialists whenever you require assistance.
                        </Typography>

                    </div>

                </div>
            </section>



        </MainLayout>
    )
}