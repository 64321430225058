import React from 'react';
import {useParams} from "react-router-dom";
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import amazonAds from "../../../Assets/Images/story/business.svg"
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineItem,
    Typography
} from "@material-tailwind/react";
import {FaRegLightbulb} from "react-icons/fa";
import {BsSpeedometer2} from "react-icons/bs";
import {MdOutlineAnalytics} from "react-icons/md";
import experts from "../../../Assets/Images/story/experts.png";
import bannersAds from "../../../Assets/Images/banners/Amazon PPC.webp";


export default function AmazonAds() {
    let {service_name} = useParams();
    service_name = "amazon-ads" ? "Amazon Advertising" : "Amazon Advertising";
    const bannerTxt = "Cultivating Success with Amazon Advertising The way you organize, oversee, and fine-tune your campaigns can make all the difference in achieving optimal visibility, scalability, and ensuring that your ad sales don't compromise your organic rankings.";
    return (
        <div>
            <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={bannersAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">Ra Digital Transformations
                            Lead to Remarkable
                            Outcomes</Typography>
                        <Typography color="white" variant="lead">
                            As a team, we anchor our collective commitment in the transformative power of steadfastness.
                            Aligned with our core company principles, we inspire and propel each other towards
                            excellence in every endeavor undertaken on behalf of our esteemed clients. Embracing a
                            proactive perspective, we perceive every obstacle encountered as a unique opportunity for
                            continuous growth, irrespective of its inherent challenges.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Our Approach to Managing Amazon PPC Campaigns
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <FaRegLightbulb className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Creative Solutions.
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Tailored to your objectives, our in-house creative team will enhance your listings
                                    to generate the highest sales impact in alignment with your personalized advertising
                                    strategy.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <BsSpeedometer2 className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Instant Communication Channel
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gray" className="font-normal text-gray-600">
                                    Experience customized support through dedicated communication channels that allow
                                    direct communication with our experts to get instant answers to your questions
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAnalytics className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Informative Analytics
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Our in-depth reports present your data in a clear and actionable format, offering
                                    the insights necessary to guide informed sales decisions
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Ra Digital Transformations Lead to Remarkable Outcomes
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Ra Digital Transformations Lead to Remarkable Outcomes
                            </Typography>
                            <Typography variant="lead" className="text-slate-300 shadowText">
                                As a team, we anchor our collective commitment in the transformative power of
                                steadfastness.
                                Aligned with our core company principles, we inspire and propel each other towards
                                excellence in every endeavor undertaken on behalf of our esteemed clients. Embracing a
                                proactive perspective, we perceive every obstacle encountered as a unique opportunity
                                for
                                continuous growth, irrespective of its inherent challenges.
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={experts} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl"/>
                    </div>
                </motion.div>

            </section>
        </div>
    )
}