import React from 'react';
import ServiceDetailBanner from "../../Components/Banner/ServiceDetailBanner";
import handShake from "../../Assets/Images/story/handShake.svg";
import MainLayout from "../../Layout/MainLayout";
import ref1 from "../../Assets/Images/references/zilan.png";
import ref2 from "../../Assets/Images/references/lycia.png";
import ref3 from "../../Assets/Images/references/alessandro.png";

export function References() {
    const bannerHeader = "Our References";
    const bannerTxt = "Explore the success stories and positive experiences shared by our satisfied clients. We are proud to have collaborated with these businesses to achieve outstanding results.";

    return (
        <MainLayout>
            <ServiceDetailBanner imgLeft={true}  bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={handShake}/>

            <div className="container mx-auto my-12 p-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {references.map((reference, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img src={reference.image} alt={`Reference ${index + 1}`} className="w-full h-auto mb-4"/>
                            <h3 className="text-lg font-semibold mb-2 text-gray-300">{reference.company}</h3>
                            <p className="text-gray-600 text-justify">{reference.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </MainLayout>
    );
}

const references = [
    {
        image: ref1,
        company: "Zilan",
        description: "Zilan partnered with us to strengthen their online presence. Leveraging our comprehensive digital advertising services, including targeted PPC campaigns and strategic DSP management, we helped Tech Innovators achieve remarkable growth and brand visibility in the competitive market."
    },
    {
        image: ref2,
        company: "Lycia Home",
        description: "Lycia Home us with their Marketplace Management, focusing on various online platforms. Through effective optimization and strategic management, we supported E-Commerce Ventures in expanding their product reach, improving sales, and establishing a strong presence in diverse online marketplaces."
    },
    {
        image: ref3,
        company: "ALESSANDRO CADAMURO",
        description: "ALESSANDRO CADAMURO sought our expertise in D2C Solutions, specifically online advertising and off-platform marketing. Our tailored strategies and dynamic campaigns elevated Dynamic Innovations' brand awareness, driving substantial traffic to their website and fostering a robust online presence."
    },
];
