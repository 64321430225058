import React from "react";
import '../customCss/parallaxStar.css'
import {BasicNav} from "../Components/Navbar/BasicNav";
import Marquee from "react-fast-marquee";
import NewFooter from "../Components/Footer/NewFooter";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import logo from "../Assets/Images/RaLogos/softLogo (4).png";

function MainLayout({children, contact = true}) {
    return (
        <div>

            {/*<StickyNavbar/>*/}
            {/*<NavbarWithMegaMenu/>*/}
            {/*<ComplexNavbar/>*/}
            <Marquee className="bg-[#1e365b]">
                <p className="text-lg text-white font-montserrat">
                    Don't miss out! Get a free, expert analysis of your online store, with e-commerce solutions starting
                    at $500. Increase conversions and grow faster.
                </p>
            </Marquee>
            <BasicNav/>
            {/*<WhatsApp/>*/}
            {contact ? <div>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
            </div> : <div>
                <div id='starss'></div>
                <div id='starss2'></div>
                <div id='starss3'></div>
            </div>
            }
            {/*if contact= true min-50vh else min-100vh*/}
            <div className={contact ? "min-h-[100vh] lg:px-0" : "h-max lg:px-0"}>
                {children}
            </div>
            {/*<ScrollTop/>*/}
            <FloatingWhatsApp
                avatar={logo}
                chatMessage="Welcome to Ra Digital! How can I help you?"
                placeholder="Type your message..."
                darkMode={true}
                allowClickAway={true}
                allowEsc={true}
                chatboxHeight={350}
                notification={true}
                notificationDelay={60}
                // notificationSound={true}
                // notificationSoundSrc={'https://www.soundjay.com/button/beep-07.wav'}
                accountName={'Ra Digital'} phoneNumber={'+905396055320'}
                message={'Hello! I would like to get more information.'}
            />
            {/*<SpeedDialWithTextOutside/>*/}
            {/*<Footer className="fixed bottom"/>*/}
            <NewFooter contact={contact} className="fixed bottom"/>
        </div>
    )
}

export default MainLayout;