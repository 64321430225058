import React from 'react'
import {ClockIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {Button, Typography} from "@material-tailwind/react";
import blog1 from "../../Assets/Images/blog/blog1.webp";
import blog2 from "../../Assets/Images/blog/blog2.webp";
import blog3 from  "../../Assets/Images/blog/blog3.webp"
export const BlogCard = () => {

    const blogData = [
        {
            id: 1,
            title: "Key Basics of Amazon Advertising: Your Ultimate Guide",
            image: blog1,
            description: "Amazon advertising, a crucial aspect of e-commerce success, demands attention to fundamental principles for optimal results. Let's delve into the essentials",
            type: "Amazon",
            date: "2023-09-01"
        },
        {
            id: 2,
            title: "Boost Your Amazon Listing Performance with These SEO Tips",
            image: blog2,
            description: "In the competitive landscape of Amazon, optimizing your product listings is essential for visibility and sales. Here are some SEO tips to enhance your Amazon listings",
            type: "SEO",
            date: "2023-12-24"
        },
        {
            id: 3,
            title: "Optimizing Amazon Sponsored Product Ads for Maximum Results",
            image: blog3,
            description: "When it comes to Amazon advertising, Sponsored Product Ads are a game-changer for boosting product visibility and driving sales. Here are some strategies to optimize your Sponsored Product Ads for maximum impact:",
            type: "Amazon",
            date: "2024-01-07"
        }
    ]

    return (
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">

            <div className="border-b mb-5 flex justify-between text-sm">
                <div className="flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">
                    <ClockIcon className="h-6 mr-3 text-white"/>
                    <Typography color="white" variant="h5" className="font-montserrat">Latest Blogs</Typography>
                </div>
                <Button size="sm" variant="filled" color="gray" className="normal-case">
                    Avarage read time 6 mins.
                </Button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                {blogData.map((blog, index) => (
                    <div className="rounded overflow-hidden shadow-lg flex flex-col bg-gray-200">
                        <div className="relative h-[350px]">
                            <Link to={`/resources/blog/${blog?.id}`}>
                                <img className="w-full h-full"
                                     src={blog?.image}
                                     alt={blog?.image}/>
                                <div
                                    className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                                </div>
                            </Link>
                            <Button size="sm" variant="filled"
                                    className="text-xs absolute top-0 right-0  px-4 py-2 text-white mt-3 mr-3
                                     hover:bg-mainColorSoft hover:text-gray-200 transition duration-300 ease-in-out">
                                {blog?.type}
                            </Button>
                        </div>
                        <div className="px-6 py-4 mb-auto">
                            <Link to={`/resources/blog/${blog?.id}`}
                                  className="font-medium text-lg inline-block hover:text-gray-700 transition duration-500 ease-in-out inline-block mb-2 truncate-text-2">
                                {blog?.title}
                            </Link>
                            <p className="text-gray-500 text-sm truncate-text-3">
                                {blog?.description}
                            </p>
                        </div>
                        <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
                <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
                    <ClockIcon className="h-5 mr-1"/>
                    <span className="ml-1">
                        {blog?.date}
                    </span>
                </span>
                            {/*<span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">*/}
                            {/*  <svg className="h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
                            {/*      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                            {/*            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z">*/}
                            {/*      </path>*/}
                            {/*  </svg>*/}
                            {/*    <span className="ml-1">39 Comments</span>*/}
                            {/*</span>*/}
                            <Link to={`/resources/blog/${blog?.id}`} className="inline-block">
                                <Button size="sm" variant="text" className="flex items-center gap-2">
                                    Learn More
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        className="h-4 w-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Button>
                            </Link>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>)
}
