import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import adsBanner from "../../../Assets/Images/story/growing.svg"
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineItem,
    Typography
} from "@material-tailwind/react";
import {FaRegLightbulb} from "react-icons/fa";
import {BsSpeedometer2} from "react-icons/bs";
import {MdOutlineAdsClick, MdOutlineAnalytics} from "react-icons/md";
import experts from "../../../Assets/Images/story/experts.png";
import bannersAds from "../../../Assets/Images/banners/Google ADS.webp";

export default function GoogleAds() {
    const bannerHeader = "Maximize Your Impact with Google Ads";
    const bannerTxt = "Unlock success by strategically managing your Google Ads campaigns. We ensure careful organization, continuous improvement, and maximum visibility for impactful advertising.";
    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Ra Digital: Where Transformations Yield Extraordinary Results
                        </Typography>
                        <Typography color="white" variant="lead">
                            At Ra Digital, we fortify our team with the unwavering strength of steadfastness. Aligned
                            with our collective company principles, we cultivate an environment that inspires each team
                            member to exceed expectations in every client endeavor. Embracing challenges as catalysts
                            for growth, we approach obstacles with resilience and determination, regardless of their
                            nature
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Our Approach to Google Ads PPC Campaign Management
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <FaRegLightbulb className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Creative Solutions
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Tailored to your objectives, our in-house creative team elevates your listings,
                                    generating maximum sales impact in harmony with your personalized advertising
                                    strategy.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <BsSpeedometer2 className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Dedicated Communication Channel
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Experience personalized support through dedicated communication channels,
                                    facilitating direct interaction with our experts for instant answers to your
                                    questions.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAnalytics className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Insightful Analytics
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Our comprehensive reports present your data in a clear, actionable format, providing
                                    insights essential for making informed sales decisions.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="p-2">
                                    <MdOutlineAdsClick className="h-4 w-4"/>
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    One-Click Access to Expertise
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Our experienced Google Ads team implements sophisticated strategies and tactics,
                                    thoroughly optimizing your ad approach. Trained by industry leaders, our commitment
                                    is to save clients time and money with unparalleled precision. Our passion for our
                                    craft is evident, and we take pride in sharing our expertise with valued customers.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Ra Digital Transformations Lead to Remarkable Outcomes
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Ra Digital Transformations Lead to Remarkable Outcomes
                            </Typography>
                            <Typography color="white" variant="lead">
                                As a team, we anchor our collective commitment in the transformative power of
                                steadfastness.
                                Aligned with our core company principles, we inspire and propel each other towards
                                excellence in every endeavor undertaken on behalf of our esteemed clients. Embracing a
                                proactive perspective, we perceive every obstacle encountered as a unique opportunity
                                for
                                continuous growth, irrespective of its inherent challenges.
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={experts} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl"/>
                    </div>
                </motion.div>

            </section>
        </div>
    )
}