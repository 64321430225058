import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import etsyBanner from "../../../Assets/Images/story/moneyIncome2.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import {useParams} from "react-router-dom";
import bannersEtsy from "../../../Assets/Images/banners/Etsy Market Management.webp";

export default function EtsyMarketManagement() {
    let {service_name} = useParams();
    service_name = "etsy_market_management" ? "Etsy Marketplace Services" : "Etsy Marketplace Services"
    const bannerTxt = "Optimize your Etsy presence with Ra Digital. Explore our services tailored to enhance visibility, drive sales, and elevate your brand on the Etsy Marketplace.";
    return (
        <div>
            <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={bannersEtsy}/>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Account Management
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Catalog Management
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Optimize your Etsy product listings for maximum impact. Our catalog management
                                    services ensure that your products are presented effectively, increasing their
                                    appeal to potential customers.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    SEO
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Enhance the discoverability of your products through effective SEO strategies. We
                                    optimize your product listings to align with Etsy's search algorithms, ensuring your
                                    products are prominently featured.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Price and Profit Calculation
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Strategically set prices to remain competitive while maximizing profitability. Our
                                    team conducts thorough market research and analysis to help you determine the
                                    optimal pricing strategy for your Etsy products.

                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Market Analysis
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay ahead of the competition with in-depth market analysis. We monitor industry
                                    trends, competitor activities, and customer behavior to provide insights that inform
                                    your business strategy on the Etsy Marketplace.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Promotion and Discount Management
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Boost your Etsy sales with targeted promotions and discounts. Our team designs and
                                    implements effective promotional campaigns that align with your business goals and
                                    resonate with your target audience.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Etsy Marketplace?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Etsy Marketplace?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Etsy Expertise: Our team has a proven track record in navigating and optimizing
                                        Etsy Marketplace accounts.
                                    </li>
                                    <li>
                                        Comprehensive Solutions: From catalog management to promotion strategies, we
                                        offer a holistic approach to ensure your success on the platform.
                                    </li>
                                    <li>
                                        Customized Strategies: Recognizing the uniqueness of each brand, we tailor our
                                        services to align with your specific goals and brand identity.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Ready to elevate your brand's presence on Etsy? Reach out to Ra Digital today to discover how
                        our comprehensive services can unlock your Etsy account's full potential, boost sales, and drive
                        enduring success on the Etsy platform."
                    </Typography>
                </div>
            </section>
        </div>


    )
}