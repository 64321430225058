import React from 'react';
import MainLayout from "../../Layout/MainLayout";
const faqData = [
    {
        question: "What strategies can be employed in Amazon PPC for optimal performance?",
        answer: "Amazon PPC strategies involve thorough keyword research, compelling ad copy, strategic bidding, and continuous performance analysis. Employing a mix of manual and automatic targeting can enhance campaign effectiveness.",
    },
    {
        question: "How does Amazon DSP contribute to a comprehensive advertising strategy?",
        answer: "Amazon DSP enhances advertising reach beyond Amazon by targeting audiences across the web. It offers advanced targeting options, including demographics, shopping behavior, and interests, providing advertisers with a powerful tool for brand exposure.",
    },
    {
        question: "How can A+ Content on Amazon positively impact product conversions?",
        answer: "A+ Content enriches product detail pages with engaging visuals and detailed information. It can improve customer trust, highlight product features, and differentiate your brand, ultimately leading to higher conversion rates.",
    },
    {
        question: "What are the key elements of effective SEO for Amazon product listings?",
        answer: "Effective Amazon SEO includes optimizing backend keywords, creating compelling bullet points, utilizing relevant search terms, and obtaining positive customer reviews. A holistic approach to listing optimization can boost search visibility and sales.",
    },
    {
        question: "How does Brand Registry contribute to brand protection on Amazon?",
        answer: "Amazon Brand Registry provides tools for brand owners to protect their intellectual property. It offers benefits such as brand content control, early detection of potential infringements, and access to additional marketing features.",
    },
    {
        question: "What are the essential components of successful Social Media Management?",
        answer: "Successful Social Media Management involves content planning, audience engagement, data analysis, and strategic use of various platforms. Building a consistent brand presence and fostering meaningful connections with the audience are key aspects.",
    },
    {
        question: "What logistics management practices ensure efficient order fulfillment on Amazon?",
        answer: "Efficient logistics management on Amazon includes inventory planning, order processing optimization, and choosing reliable shipping partners. Implementing Amazon FBA (Fulfillment by Amazon) can also streamline the fulfillment process.",
    },
    {
        question: "How can businesses optimize their presence on the Walmart marketplace?",
        answer: "Optimizing presence on Walmart involves strategic product listings, competitive pricing, and leveraging Walmart's advertising platform. Regularly monitoring performance metrics and customer feedback is crucial for ongoing success.",
    },
    {
        question: "What strategies can be employed for successful Etsy market management?",
        answer: "Successful Etsy market management involves creating unique product listings, utilizing Etsy's search algorithm, and implementing effective marketing strategies. Building a positive reputation through excellent customer service is key to long-term success.",
    },
    {
        question: "How can businesses effectively manage their presence on Trendyol?",
        answer: "Effective Trendyol store management includes optimizing product listings, utilizing promotions, and providing excellent customer service. Staying informed about marketplace trends and adapting strategies accordingly is essential for success.",
    },
    {
        question: "What practices contribute to successful Shopify market management?",
        answer: "Successful Shopify market management involves creating an appealing storefront, optimizing product listings, and implementing marketing strategies. Utilizing Shopify's built-in tools and apps can enhance the overall shopping experience.",
    },
    {
        question: "What strategies are effective for Google Ads campaign success?",
        answer: "Effective Google Ads campaigns involve thorough keyword research, compelling ad copy, strategic bidding, and continuous performance analysis. Utilizing ad extensions and tracking conversion metrics can further optimize campaign performance.",
    },
    {
        question: "How can businesses effectively manage and optimize Meta Ads?",
        answer: "Effectively managing Meta Ads involves defining clear campaign objectives, targeting the right audience, and creating engaging ad content. Regularly analyzing ad performance and making data-driven adjustments are key to success.",
    },
    {
        question: "What is the significance of off-Amazon marketing in e-commerce?",
        answer: "Off-Amazon marketing is essential for diversifying traffic sources and reducing reliance on a single platform. It involves strategies such as social media marketing, email campaigns, and influencer collaborations to drive traffic to Amazon listings.",
    },
    {
        question: "What considerations are important for website development in D2C solutions?",
        answer: "Website development for D2C solutions requires a strategic approach, including choosing the right technology stack (such as React, HTML, CSS, and JavaScript), creating a user-friendly interface, and implementing robust security measures.",
    },
];

export default function FAQ() {
    return (
        <MainLayout>
            <div className="py-10 px-8 max-w-5xl mx-auto flex flex-col md:flex-row gap-12 text-white">
                <div className="md:sticky md:top-44 flex flex-col text-left basis-1/2 h-max ">
                    <p className="inline-block font-semibold mb-4">Ra Digital FAQ</p>
                    <p className="sm:text-4xl text-3xl font-extrabold text-base-content">Frequently Asked Questions</p>
                </div>
                <ul className="basis-1/2">
                    {faqData.map((item, index) => (
                        <li key={index}>
                            <button
                                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t border-base-content/10"
                                aria-expanded="false"
                                onClick={() => toggleFAQ(index)}
                            >
                                <span className="flex-1">{item.question}</span>
                                <svg className="flex-shrink-0 w-4 h-4 ml-auto fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="7" width="16" height="2" rx="1" className="transform origin-center transition duration-200 ease-out false"></rect>
                                    <rect y="7" width="16" height="2" rx="1" className="transform origin-center rotate-90 transition duration-200 ease-out false"></rect>
                                </svg>
                            </button>
                            <div
                                className="transition-all duration-300 ease-in-out max-h-0 overflow-hidden"
                                style={{ transition: "max-height 0.3s ease-in-out 0s", maxHeight: "0" }}
                            >
                                <div className="pb-5 leading-relaxed">
                                    <div className="space-y-2 leading-relaxed">{item.answer}</div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </MainLayout>

    );
}

function toggleFAQ(index) {
    const button = document.querySelectorAll('button[aria-expanded]')[index];
    const content = button.nextElementSibling;
    button.setAttribute("aria-expanded", button.getAttribute("aria-expanded") === "false" ? "true" : "false");
    content.style.maxHeight = button.getAttribute("aria-expanded") === "true" ? content.scrollHeight + "px" : "0";
}
