import React from 'react';
import {Button, Card, CardBody, CardHeader, Carousel, Typography} from "@material-tailwind/react";
import PageBanner from "../../../Components/Banner/ServiceDetailBanner";
import caseStudiesBanner from "../../../Assets/Images/story/aPlusContentBottom.svg";
import caseStudy1 from "../../../Assets/Images/serviceDetailBanner/caseStudy1.webp";
import caseStudy2 from "../../../Assets/Images/serviceDetailBanner/caseStudy2.webp";
import {
    Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import {PresentationChartLineIcon, ShareIcon} from "@heroicons/react/24/outline";

export default function CaseStudies() {
    const bannerHeader = "Case Studies: Real-world Success Stories";
    const bannerTxt = "Explore our case studies to discover how Ra Digital has helped businesses succeed in the e-commerce landscape.";

    return (
        <div>
            <PageBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={caseStudiesBanner}
                        imgLeft={true}/>


            <div className="container mx-auto px-6 flex flex-col">
                <Typography color="gray" className="text-center text-2xl font-semibold my-8 ">
                    Leveraging Amazon Attribution to Boost Off-Amazon Sales and Brand Awareness for Guitar Strap
                </Typography>
                <div className="flex lg:flex-row flex-col justify-between gap-4">
                    <div className="lg:w-1/2 flex flex-col gap-4 justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <Square3Stack3DIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Challenge</Typography>
                                <Typography color="gray" variant="paragraph">Because of Glance View
                                    decreases on Amazon ; Boost traffic with Off-Amazon
                                    Improving visibility & Off-Amazon sales & Brand awareness of guitar strap
                                    products
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <ShareIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Strategy</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Supporting awareness and sales via Google Ads, Instagram/Facebook, TikTok
                                    platforms
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <PresentationChartLineIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Execution</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Improving Bestseller Ranking: Increased traffic and conversions improve your
                                    Bestseller Ranking, leading to higher visibility on Amazon.
                                    Skipping Competition: External marketing channels help you avoid the crowded
                                    marketplace and drive potential customers directly to your product pages
                                    Increase Sales & Brand Awareness: External sources attract leads from various
                                    platforms, leading to higher sales on Amazon

                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 flex flex-col gap-4  items-center justify-center">
                        <Card
                            shadow={false}
                            className="relative grid w-full min-h-[400px] max-w-[28rem] items-end justify-center overflow-hidden text-center"
                        >
                            <CardHeader
                                floated={false}
                                shadow={false}
                                color="transparent"
                                className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://img.freepik.com/free-photo/bags-with-stuff-baby_23-2147698845.jpg?w=996&t=st=1707647598~exp=1707648198~hmac=afd51528d6b916edaf15550607f5fadfe61165484bbd038ff302e51bb3065d69')] bg-cover bg-center"
                            >
                                <div
                                    className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50"/>
                            </CardHeader>
                            <CardBody className="relative py-14 px-6 md:px-12">
                                <Typography
                                    color="white"
                                    className="md:text-3xl text-xl mb-6 font-bold leading-[1.5]"
                                >
                                    Launching a brand new product and competing against established players on Amazon
                                    can be daunting.
                                </Typography>
                                <Typography className="md:text-xl text-lg mb-4 text-gray-400">
                                    This client needed to build brand awareness, drive sales, and establish a strong
                                    foothold in a crowded market within a limited timeframe.
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <Carousel
                    className="rounded-xl pt-8"
                    loop={true}
                    navigation={({setActiveIndex, activeIndex, length}) => (
                        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                            {new Array(length).fill("").map((_, i) => (
                                <span
                                    key={i}
                                    className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                        activeIndex === i ? "w-8 bg-black" : "w-4 bg-black/50"
                                    }`}
                                    onClick={() => setActiveIndex(i)}
                                />
                            ))}
                        </div>
                    )}
                >
                    <img
                        src={caseStudy1}
                        alt="case"
                        className="h-full w-full rounded-lg object-contain sm:px-12"
                    />
                    <img
                        src={caseStudy1}
                        alt="case"
                        className="h-full w-full rounded-lg object-contain sm:px-12"
                    />
                </Carousel>

                <Typography color="gray" className="text-center text-2xl font-semibold my-8">
                    Digital Gaming Products Seller, Boosts Sales with Google Ads
                </Typography>
                <div className="flex lg:flex-row flex-col justify-between gap-4">
                    <div className="lg:w-1/2 flex flex-col gap-4 items-center justify-center">
                        <Card
                            shadow={false}
                            className="relative grid w-full min-h-[400px] max-w-[28rem] items-end justify-center overflow-hidden text-center"
                        >
                            <CardHeader
                                floated={false}
                                shadow={false}
                                color="transparent"
                                className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://img.freepik.com/free-vector/growth-curve-concept-illustration_114360-4705.jpg?w=740&t=st=1707648663~exp=1707649263~hmac=955af34d11788d19065f84e541b8a7f9320876bfdd6b5fc61a82a5551f1e348b')] bg-cover bg-center"
                            >
                                <div
                                    className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50"/>
                            </CardHeader>
                            <CardBody className="relative py-14 px-6 md:px-12">
                                <Typography
                                    color="white"
                                    className="md:text-3xl text-xl mb-6 font-bold leading-[1.5]"
                                >
                                    Elevating Online Presence
                                </Typography>
                                <Typography className="md:text-xl text-lg mb-4 text-gray-400">
                                    Explore how we transformed online visibility and increased sales through strategic
                                    Google Ads optimization.
                                </Typography>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="lg:w-1/2 flex flex-col gap-4 justify-center">
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <Square3Stack3DIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Challenge</Typography>
                                <div>
                                    <Typography color="gray" variant="paragraph">
                                        Faced challenges reaching the target audience and converting website visitors
                                        into customers. Aiming to enhance the online advertising strategy to drive more
                                        qualified traffic and increase sales.
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <ShareIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Strategy</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Conducted thorough keyword research, refined ad copies, and launched targeted
                                    campaigns for specific demographics. Developed responsive ads for optimal
                                    performance and implemented conversion tracking.
                                </Typography>
                            </div>
                        </div>
                        <div className="flex flex-row items-center gap-2">
                            <div className="w-[50px] h-[50px]">
                                <PresentationChartLineIcon className="w-[50px] h-[50px] text-white"/>
                            </div>
                            <div className="">
                                <Typography color="white" variant="h6">Execution</Typography>
                                <Typography color="gray" variant="paragraph">
                                    Executed Google Ads strategy leading to a remarkable 61% increase in online sales
                                    within 10 months. Achieved a 20% reduction in CPA, enhancing overall advertising
                                    cost-effectiveness. Marked a 200% increase in brand name keyword searches and a 50%
                                    decrease in CPC, boosting brand awareness.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}