import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import mainLogo from "../../Assets/Images/RaLogos/raDigitalWhite.webp";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {BsFacebook, BsInstagram, BsLinkedin, BsTwitter} from "react-icons/bs";
import {Typography} from "@material-tailwind/react";

const menuVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
};

const subMenuVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-20px' },
};

export const DrawerNav = ({ servicesMenuItems, resourcesMenuItems }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeMainMenu, setActiveMainMenu] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
        setActiveMainMenu(null);
        setActiveSubMenu(null);
    };

    const handleMainMenuClick = (menuTitle) => {
        setActiveMainMenu((prevActiveMenu) => (prevActiveMenu === menuTitle ? null : menuTitle));
        setActiveSubMenu(null);
    };

    const handleSubMenuClick = (event, subMenuTitle) => {
        event.stopPropagation();
        setActiveSubMenu((prevActiveSubMenu) => (prevActiveSubMenu === subMenuTitle ? null : subMenuTitle));
    };

    return (
        <div className="max-w-full rounded-none py-2 bg-mainColor !border-none shadow-3xl">
            <div className="flex justify-between items-center px-4">
                {/* Logo */}
                <Link to="/" className="cursor-pointer">
                    <img src={mainLogo} alt="" className="w-44 h-10 object-contain" />
                </Link>

                {/* Hamburger Menu */}
                <div className="cursor-pointer text-white md:hidden" onClick={toggleDrawer}>
                    {isDrawerOpen ? '✕' : '☰'}
                </div>

                {/* Drawer */}
                <AnimatePresence>
                    {isDrawerOpen && (
                        <>
                            <motion.div
                                key="drawer-bg"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.5 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                className="fixed inset-0 bg-black z-50"
                                style={{ backdropFilter: 'blur(10px)' }}
                                onClick={toggleDrawer}
                            />

                            <motion.div
                                key="drawer-content"
                                variants={menuVariants}
                                initial="closed"
                                animate="open"
                                exit="closed"
                                transition={{ duration: 0.3 }}
                                className="fixed h-full w-[17rem] p-4 top-0 left-0 z-50 bg-mainColor !border-none shadow-2xl"
                            >
                                {/* Drawer content */}
                                <Link to="/" className="cursor-pointer">
                                    <img src={mainLogo} alt="" className="w-44 h-10 object-contain" />
                                </Link>
                                <div className="flex flex-col justify-between h-full">
                                    <div className="flex flex-col gap-2 text-white">
                                        <Link to="/" className="block sideBorder sideBorderL w-full">Home</Link>

                                        {/* Services menu */}
                                        <div className="block w-full">
                                            <motion.div
                                                className="flex items-center gap-2 cursor-pointer sideBorder sideBorderL w-full"
                                                onClick={() => handleMainMenuClick("Services")}
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    <Typography color="white" variant="h6">Services</Typography>
                                                    <motion.div
                                                        initial={false}
                                                        animate={{ rotate: activeMainMenu === "Services" ? 180 : 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <ChevronDownIcon strokeWidth={2.5} className="h-3 w-3 text-white transition-transform md:block" />
                                                    </motion.div>
                                                </div>
                                            </motion.div>
                                            {activeMainMenu === "Services" && (
                                                <motion.div
                                                    variants={subMenuVariants}
                                                    initial="closed"
                                                    animate="open"
                                                    exit="closed"
                                                    transition={{ duration: 0.2 }}
                                                    className="pl-4 "
                                                >
                                                    {servicesMenuItems.map((menuItem, index) => (
                                                        <div className="block py-1 w-full" key={index}>
                                                            <motion.div
                                                                className="flex items-center gap-2 cursor-pointer sideBorder sideBorderL w-full"
                                                                onClick={(event) => handleSubMenuClick(event, menuItem.title, menuItem.subMenu)}
                                                            >
                                                                <div className="w-full flex items-center justify-between">
                                                                    <Typography color="white" variant="h6" className="text-sm"> {menuItem.title}</Typography>
                                                                    <motion.div
                                                                        initial={false}
                                                                        animate={{ rotate: activeSubMenu === menuItem.title ? 180 : 0 }}
                                                                        transition={{ duration: 0.3 }}
                                                                    >
                                                                        <ChevronDownIcon strokeWidth={2.5} className="h-3 w-3 text-white transition-transform md:block" />
                                                                    </motion.div>
                                                                </div>
                                                            </motion.div>
                                                            {activeSubMenu === menuItem.title && (
                                                                <motion.div
                                                                    variants={subMenuVariants}
                                                                    initial="closed"
                                                                    animate="open"
                                                                    exit="closed"
                                                                    transition={{ duration: 0.2 }}
                                                                    className="pl-4"
                                                                >
                                                                    {menuItem.subMenu.map((subMenuItem, subIndex) => (
                                                                        <Link
                                                                            key={subIndex}
                                                                            to={subMenuItem.to}
                                                                            className="block py-2 sideBorder sideBorderL"
                                                                            onClick={() => toggleDrawer()}
                                                                        >
                                                                            {subMenuItem.title}
                                                                        </Link>
                                                                    ))}
                                                                </motion.div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </motion.div>
                                            )}
                                        </div>

                                        {/* Resources menu */}
                                        <motion.div
                                            className="block"
                                            variants={menuVariants}
                                            initial="closed"
                                            animate="open"
                                            exit="closed"
                                            transition={{ duration: 0.3 }}
                                        >
                                            <motion.div
                                                className="flex items-center gap-2 cursor-pointer sideBorder sideBorderL w-full"
                                                onClick={() => handleMainMenuClick("Resources")}
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    <span>Resources</span>
                                                    <motion.div
                                                        initial={false}
                                                        animate={{ rotate: activeMainMenu === "Resources" ? 180 : 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <ChevronDownIcon strokeWidth={2.5} className="h-3 w-3 text-white transition-transform md:block" />
                                                    </motion.div>
                                                </div>

                                            </motion.div>
                                            {activeMainMenu === "Resources" && (
                                                <motion.div
                                                    variants={subMenuVariants}
                                                    initial="closed"
                                                    animate="open"
                                                    exit="closed"
                                                    transition={{ duration: 0.2 }}
                                                    className="pl-4"
                                                >
                                                    {resourcesMenuItems.map((resource, index) => (
                                                        <Link key={index} to={resource.to} onClick={toggleDrawer}
                                                              className="block py-2 w-full sideBorder sideBorderL">{resource.title}</Link>
                                                    ))}
                                                </motion.div>
                                            )}
                                        </motion.div>

                                        <Link to="/about" className="block py-1 sideBorder sideBorderL w-full">About</Link>
                                    </div>
                                    <ul className="wrapper flex gap-4 mb-8">
                                        <Link to="https://www.linkedin.com/company/radigital-io/about/?viewAsMember=true">
                                            <li className="icon facebook">
                                                <span className="tooltip">Linkedin</span>
                                                <BsLinkedin />
                                            </li>
                                        </Link>
                                        {/*<Link to="/">*/}
                                        {/*      <li className="icon twitter">*/}
                                        {/*           <span className="tooltip">Twitter</span>*/}
                                        {/*           <BsTwitter/>*/}
                                        {/*      </li>*/}
                                        {/*</Link>*/}
                                        <Link to="https://www.instagram.com/radigital_io/">
                                            <li className="icon instagram">
                                                <span className="tooltip">Instagram</span>
                                                <BsInstagram/>
                                            </li>
                                        </Link>
                                    </ul>
                                </div>
                            </motion.div>
                        </>
                    )}
                </AnimatePresence>


            </div>

        </div>
    );
};
