import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import storeDesignBanner from "../../../Assets/Images/story/storeDesignBanner.svg";
import storeDesignMultimedia from "../../../Assets/Images/story/storeDesignOffer.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersStoreDesign from "../../../Assets/Images/banners/Store Page Design.webp";

export default function StorePageDesign() {
    const bannerHeader = "Store Page Design: Crafting Your Digital Storefront";
    const bannerTxt =  "Elevate your brand's online presence with our store page design service, creating an immersive and intuitive shopping experience for your customers.";

    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersStoreDesign}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Our Store Page Design Approach
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Elevate your brand's digital storefront with Ra Digital. Our store page design service
                        focuses on creating an immersive and intuitive shopping experience that aligns with your brand's
                        ethos.
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Key Features of Store Page Design
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Custom Layout and Navigation Design
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Enhance user experience and product discoverability with custom layout and
                                    navigation design.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Integration of Multimedia Elements
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Engage customers with multimedia elements such as videos and high-resolution images.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    A/B Testing for Continuous Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Implement A/B testing for different design elements to continuously optimize for
                                    conversion and customer satisfaction.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        {/* Add more items as needed for Store Page Design */}
                        {/* ... */}
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Store Page Design?
                    </Typography>
                    <div className="flex flex-row justify-center items-center gap-3 w-full">
                        <div className="lg:px-6 lg:w-[55%] md:w-2/3 w-full">
                            <div className="flex flex-col justify-center items-center gap-3 w-full">
                                <Typography color="white" variant="h3"
                                            className="lg:block hidden text-center nbXs:text-center pb-3">
                                    Why Choose Ra Digital for Store Page Design?
                                </Typography>
                                <Typography color="white" variant="lead">
                                    <ul className="list-disc">
                                        <li>
                                            Expert Design: Our team specializes in crafting custom layouts and
                                            navigation
                                            designs to enhance user experience and product discoverability.
                                        </li>
                                        <li>
                                            Multimedia Integration: We bring your store page to life by integrating
                                            multimedia elements like videos and high-resolution images to engage
                                            customers.
                                        </li>
                                        <li>
                                            Continuous Optimization: Implement A/B testing for different design elements
                                            to
                                            continuously optimize for conversion and customer satisfaction.
                                        </li>
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                        <div className="lg:w-[45%] w-1/3 lg:max-h-[325x] md:block hidden">
                            <img src={storeDesignMultimedia} alt="store-design-multimedia"
                                 className="lg:w-full lg:h-full h-[250px] object-contain drop-shadow-xl"/>
                        </div>
                    </div>

                </motion.div>
            </section>
        </div>
    );
}
