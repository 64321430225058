import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import logisticBanner from "../../../Assets/Images/story/logisticBanner.svg";
import logisticBottom from "../../../Assets/Images/story/logisticBottom.svg";
import {
    Typography, Timeline, TimelineItem, TimelineConnector, TimelineHeader, TimelineIcon, TimelineBody
} from "@material-tailwind/react";
import {motion} from "framer-motion";
import bannersInventory from "../../../Assets/Images/banners/Inventory Management.webp";

export default function LogisticManagement() {
    const bannerHeader = "Optimized Inventory Management";
    const bannerTxt = "Streamline your supply chain with Ra Digital's inventory management solutions. Our comprehensive services ensure efficient logistics, guaranteeing your products are always in the right place at the right time.";

    return (
        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersInventory}/>
            <motion.section
                initial={{opacity: 0, y: 20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.6}}
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Our Logistic Management Approach
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 lg:text-xl text-lg nbXs:text-base">
                        Streamline your supply chain with Ra Digital's Logistic Management solutions. We optimize
                        logistics
                        to ensure your products are in the right place at the right time. Our comprehensive services
                        include:
                    </Typography>
                </div>
            </motion.section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Key Features of Logistic Management
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Inventory Management Strategies
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Implement strategies to balance supply and demand, reducing holding costs while
                                    ensuring stock availability.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Order Fulfillment Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Improve delivery times to enhance customer satisfaction and loyalty through order
                                    fulfillment optimization.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Customized Reporting and Analytics
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Access customized reporting and analytics for continuous improvement in logistics
                                    performance.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        {/* Add more items as needed for Logistic Management */}
                        {/* ... */}
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Logistic Management?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Logistic Management?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Efficient Strategies: Implement inventory management strategies to balance
                                        supply and demand, reducing holding costs.
                                    </li>
                                    <li>
                                        Improved Fulfillment: Optimize order fulfillment to improve delivery times,
                                        enhancing customer satisfaction and loyalty.
                                    </li>
                                    <li>
                                        Data-Driven Decisions: Access customized reporting and analytics for continuous
                                        improvement in logistics performance.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-2/3 lg:max-h-[325x]">
                        <img src={logisticBottom} alt="inventory-management"
                             className="w-full max-h-full nbXs:max-h-[275px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>
        </div>
    );
}
