import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CustomButton} from "../Buttons/CustomButton";
import 'react-toastify/dist/ReactToastify.css';
import {Input, Textarea} from "@material-tailwind/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css"
import {useNavigate} from "react-router-dom";

export const Form = ({contact=false}) => {
    const windowPath = window.location.pathname;
    const navigate = useNavigate();
    const form = useRef();
    const [formData, setFormData] = useState({
        form_name: '',
        form_phone: '',
        form_email: '',
        form_msg: '',
        form_company: '',
        form_path: windowPath,
    });

    const sendEmail = async (e) => {
        e.preventDefault();

        if (!isNameValid(formData.form_name)) {
            toast.error("Invalid name! It should be maximum 25 characters.");
            return;
        }

        if (!isCompanyNameValid(formData.form_company)) {
            toast.error("Invalid company name! It should be maximum 30 characters.");
            return;
        }

        if (!isPhoneNumberValid(formData.form_phone)) {
            toast.error("Invalid phone number! It should be up to 15 digits and contain only numbers.");
            return;
        }

        if (!isEmailValid(formData.form_email)) {
            toast.error("Invalid email address!");
            return;
        }

        if (!isMessageValid(formData.form_msg)) {
            toast.error("Message should not exceed 500 characters.");
            return;
        }

        try {
            const result = await emailjs.sendForm('service_tki6w8j', 'template_mfcj0a9', form.current, 'IzCi63lUXX6Cz3hUk');
            console.log(result.text);
            setFormData({
                form_name: '',
                form_phone: '',
                form_email: '',
                form_msg: '',
                form_company: '',
                form_path: windowPath,
            });

            toast.success("Email sent successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            navigate('/form-success');
        } catch (error) {
            console.error(error.text);
            toast.error("Email sending failed!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };
    // İsim için maksimum 25 karakter kontrolü
    const isNameValid = (name) => {
        return name.length <= 25;
    };

    // Şirket adı için maksimum 30 karakter kontrolü
    const isCompanyNameValid = (companyName) => {
        return companyName.length <= 30;
    };

    // Telefon numarası için sayısal ve maksimum 15 hane kontrolü
    const isPhoneNumberValid = (phoneNumber) => {
        return /^\d{1,15}$/.test(phoneNumber);
    };

    // E-posta adresi için doğru format kontrolü
    const isEmailValid = (email) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    };

    // Textarea için maksimum 500 karakter kontrolü
    const isMessageValid = (message) => {
        return message.length <= 500;
    };
    const handlePhoneInputChange = (phone) => {
        setFormData({...formData, form_phone: phone});
    };
    return (
        <div>
            {/*bg-[#5A648D33]*/}
            {
                !contact ? (
                    <section className="sm:px-6 px-2 py-8 mt-6 w-full bg-[#5A648D26]">
                        <div className="max-w-xl mx-auto">
                            <div className="text-center ">
                                <div className="relative flex flex-col items-center">
                                    <div
                                        className="absolute hidden md:block -top-14 left-0 text-[120px] text-gray-400 font-bold opacity-5 uppercase">
                                        Contact
                                    </div>
                                    <h1 className="text-5xl font-bold gradientPattern"> Free Audit</h1>
                                    <div className="flex w-28 mt-1 mb-10 overflow-hidden rounded bg-gradient-to-r from-[#929afe] via-[#b387f6] to-[#de67f5] h-2"></div>
                                </div>
                                <p className="mb-4 text-base text-center text-gray-500">
                                    Boost your business! Our e-commerce services start at $500. Get a free account analysis now.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            {/*<img className="lg:block hidden w-1/3 h-96" src={contactUs} alt=""/>*/}
                            <form ref={form} onSubmit={sendEmail}
                                  className="lg:w-1/2 w-full bg-[rgba(255, 255, 255, 0.8)]">
                                <div className="mb-4 flex flex-col gap-6">
                                    <div className="flex gap-6 flex-col sm:flex-row">
                                        <Input required={true} size="lg" name="form_name" label="Name" color={"blue-gray"}
                                               title="Please enter Full Name."
                                               value={formData.form_name}
                                               onChange={(e) => {
                                                   const value = e.target.value;
                                                   // Yalnızca harf karakterlerini kabul et
                                                   if (/^[a-zA-Z\s]*$/.test(value)) {
                                                       // 25 karakter uzunluğunda bir ismi kabul et
                                                       if (value.length <= 25) {
                                                           setFormData({...formData, form_name: value});
                                                       }
                                                   }
                                               }
                                               }/>
                                        <Input required={false} size="lg" name="form_company" label="Company Name (Optional)"
                                               color={"blue-gray"}
                                               title="Please enter Company Name."
                                               value={formData.form_company ? formData.form_company : ''}
                                               onChange={(e) => setFormData({...formData, form_company: e.target.value})}/>
                                    </div>
                                    <div className="flex gap-6 flex-col sm:flex-row">
                                        <PhoneInput
                                            country={'us'}
                                            autoFormat={true}
                                            enableSearch={true}
                                            enableAreaCodes={true}
                                            required={true}
                                            placeholder={"Enter phone number"}
                                            searchPlaceholder={"Search Country"}
                                            size="md"
                                            name="form_phone"
                                            label="Phone Number"
                                            value={formData.form_phone}
                                            onChange={handlePhoneInputChange}
                                            title="Please enter only numeric characters."
                                            color="gray"
                                            containerStyle={{
                                                width: "100%",
                                                height: "100%",
                                                border: "1px solid rgb(176 190 197 ",
                                                outline: 0,
                                                borderRadius: "0.375rem",
                                            }}
                                        />
                                        <Input required={true} size="lg" name="form_email" label="Email" color={"blue-gray"}
                                               title="Please enter a valid email address."
                                               value={formData.form_email}
                                               onChange={(e) => setFormData({...formData, form_email: e.target.value})}/>
                                    </div>
                                    <Textarea required={true} size="lg" name="form_msg" label="Message" color={"blue-gray"}
                                              title="Please enter your message."
                                              value={formData.form_msg}
                                              onChange={(e) => setFormData({...formData, form_msg: e.target.value})}/>
                                    <input name="form_path" className="hidden" color={"blue-gray"}
                                           value={formData.form_path}/>
                                    <input name="form_phone" className="hidden" color={"blue-gray"}
                                           value={formData.form_phone}/>

                                </div>
                                <div className="w-full flex justify-center items-center">
                                    <CustomButton type="submit" buttonText={"Submit"} buttonClassName={"md:w-1/3"}/>
                                </div>
                            </form>

                        </div>
                    </section>
                ) : (
                    <section className="sm:px-6 px-2 py-8 w-full bg-[#5A648D26] h-full">
                        <div className="max-w-xl mx-auto">
                            <div className="text-center ">
                                <div className="relative flex flex-col items-center">
                                    <div
                                        className="absolute hidden md:block -top-14 left-0 text-[120px] text-gray-400 font-bold opacity-5 uppercase">
                                        Contact
                                    </div>
                                    <h1 className="text-5xl font-bold gradientPattern"> Contact Us</h1>
                                    <div className="flex w-28 mt-1 mb-10 overflow-hidden rounded bg-gradient-to-r from-[#929afe] via-[#b387f6] to-[#de67f5] h-2"></div>
                                </div>
                                <p className="mb-4 text-base text-center text-gray-500">
                                    We are here to help and answer any question you might have. We look forward to hearing from you.
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            {/*<img className="lg:block hidden w-1/3 h-96" src={contactUs} alt=""/>*/}
                            <form ref={form} onSubmit={sendEmail}
                                  className="lg:w-1/2 w-full bg-[rgba(255, 255, 255, 0.8)]">
                                <div className="mb-4 flex flex-col gap-6">
                                    <div className="flex gap-6 flex-col sm:flex-row">
                                        <Input required={true} size="lg" name="form_name" label="Name" color={"blue-gray"}
                                               title="Please enter Full Name."
                                               value={formData.form_name}
                                               onChange={(e) => {
                                                   const value = e.target.value;
                                                   // Yalnızca harf karakterlerini kabul et
                                                   if (/^[a-zA-Z\s]*$/.test(value)) {
                                                       // 25 karakter uzunluğunda bir ismi kabul et
                                                       if (value.length <= 25) {
                                                           setFormData({...formData, form_name: value});
                                                       }
                                                   }
                                               }
                                               }/>
                                        <Input required={false} size="lg" name="form_company" label="Company Name (Optional)"
                                               color={"blue-gray"}
                                               title="Please enter Company Name."
                                               value={formData.form_company ? formData.form_company : ''}
                                               onChange={(e) => setFormData({...formData, form_company: e.target.value})}/>
                                    </div>
                                    <div className="flex gap-6 flex-col sm:flex-row">
                                        <PhoneInput
                                            country={'us'}
                                            autoFormat={true}
                                            enableSearch={true}
                                            enableAreaCodes={true}
                                            required={true}
                                            placeholder={"Enter phone number"}
                                            searchPlaceholder={"Search Country"}
                                            size="md"
                                            name="form_phone"
                                            label="Phone Number"
                                            value={formData.form_phone}
                                            onChange={handlePhoneInputChange}
                                            title="Please enter only numeric characters."
                                            color="gray"
                                            containerStyle={{
                                                width: "100%",
                                                height: "100%",
                                                border: "1px solid rgb(176 190 197 ",
                                                outline: 0,
                                                borderRadius: "0.375rem",
                                            }}
                                        />
                                        <Input required={true} size="lg" name="form_email" label="Email" color={"blue-gray"}
                                               title="Please enter a valid email address."
                                               value={formData.form_email}
                                               onChange={(e) => setFormData({...formData, form_email: e.target.value})}/>
                                    </div>
                                    <Textarea required={true} size="lg" name="form_msg" label="Message" color={"blue-gray"}
                                              title="Please enter your message."
                                              value={formData.form_msg}
                                              onChange={(e) => setFormData({...formData, form_msg: e.target.value})}/>
                                    <input name="form_path" className="hidden" color={"blue-gray"}
                                           value={formData.form_path}/>
                                    <input name="form_phone" className="hidden" color={"blue-gray"}
                                           value={formData.form_phone}/>

                                </div>
                                <div className="w-full flex justify-center items-center">
                                    <CustomButton type="submit" buttonText={"Submit"} buttonClassName={"md:w-1/3"}/>
                                </div>
                            </form>

                        </div>
                    </section>
                )
            }
            <ToastContainer/>
        </div>
    );
};
