import {motion} from "framer-motion";
import React from "react";
import {ScoolButton} from "../Buttons/ScoolButton";
import {Typography} from "@material-tailwind/react";
const ServiceDetailBanner = ({service_name,bannerHeader, bannerImg, bannerTxt,imgLeft=false}) => {
    return (
        <div>
            {!imgLeft ? (
                    <div className="w-full flex items-center gap-8 max-w-6xl mx-auto py-2 text-white">
                        <div className="relative">
                            <motion.img
                                src={bannerImg}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.6}}
                                className="h-[300px] sm:h-max w-full rounded-lg object-contain sm:block hidden"
                            />
                            <motion.div
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.6}}
                                className="sm:absolute sm:top-[15%] sm:left-5 sm:w-1/2 sm:px-0  px-4 sm:pt-2 pt-3"
                            >
                                <Typography
                                            className="sm:text-black sm:text-start text-center text-4xl sm:text-2xl md:text-4xl font-semibold font-montserrat">
                                    {service_name ? service_name : bannerHeader}
                                </Typography>
                                <Typography  variant="lead"
                                             className="sm:text-black sm:text-start text-justify lg:text-xl sm:text-base text-lg lg:w-[85%] w-full xl:py-6 lg:py-6 py-3 font-montserrat">
                                    {bannerTxt}
                                </Typography>
                                <div className="sm:w-max w-full flex justify-center">
                                    <ScoolButton
                                        link={'contact'}
                                        buttonText={'Contact Us'}
                                        buttonClassName="backgroundGradient text-white font-montserrat py-2 px-4 rounded transition-all active:scale-95"
                                        setOffset={-76}
                                    />
                                </div>


                            </motion.div>
                        </div>

                    </div>
                )
                : (
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 items-center gap-8 px-6 max-w-6xl mx-auto py-12 text-white">
                        <div className="flex justify-center items-center md:block hidden">
                            <motion.img
                                src={bannerImg}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.6}}
                                className="h-max md:w-full sm:w-2/3 w-full rounded-lg object-contain"
                            />
                        </div>
                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.6}}
                        >
                            <Typography color="white"
                                        className="text-3xl md:text-5xl font-semibold text-mainColor nbXs:text-center text-white">
                                {service_name ? service_name : bannerHeader}
                            </Typography>
                            <Typography  variant="lead"
                                         className="text-slate-300 shadowText my-4 md:my-6 nbXs:text-justify">
                                {bannerTxt}
                            </Typography>
                            <div className="flex justify-center">
                                <ScoolButton
                                    link={'contact'}
                                    buttonText={'Contact Us'}
                                    buttonClassName="backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
                                    setOffset={-76}
                                />
                            </div>

                        </motion.div>
                    </div>
                )
            }
           </div>

    )
}
export default ServiceDetailBanner;