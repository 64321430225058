import React from 'react';
import {FaFacebook, FaInstagram, FaLinkedin, FaSquareXTwitter, FaTiktok} from "react-icons/fa6";
import {Link, useParams} from "react-router-dom";
import MainLayout from "../../../Layout/MainLayout";
import {Avatar, Typography} from "@material-tailwind/react";
import raLogo from "../../../Assets/Images/RaLogos/softLogo ico.png";
import blog1 from "../../../Assets/Images/blog/blog1.webp";
import blog2 from "../../../Assets/Images/blog/blog2.webp";
import blog3 from "../../../Assets/Images/blog/blog3.webp";

export default function BlogPost() {
    let {id} = useParams();
    const blogData = [
        {
            id: 1,
            title: 'Key Basics of Amazon Advertising: Your Ultimate Guide',
            explanation: "Amazon advertising, a crucial aspect of e-commerce success, demands attention to fundamental principles for optimal results. Let's delve into the essentials:",
            image: blog1,
            description: [
                {
                    title: "Understanding Amazon's Ad Types",
                    content:
                        "Familiarize yourself with Sponsored Products, Sponsored Brands, and Sponsored Display Ads. Each serves distinct purposes and targets different stages of the customer journey.",
                },
                {
                    title: 'Keyword Research',
                    content:
                        'Conduct thorough keyword research to identify relevant terms your audience uses to search for products. Utilize tools like Amazon\'s own keyword research tool or third-party options for comprehensive insights.',
                },
                {
                    title: 'Optimized Product Listings',
                    content:
                        'Ensure your product listings are optimized with relevant keywords, compelling descriptions, high-quality images, and competitive pricing. These elements directly impact ad performance and conversion rates.',
                },
                {
                    title: 'Budget Allocation',
                    content:
                        'Determine your advertising budget based on your business goals, product margins, and competition. Allocate budgets strategically across campaigns to maximize ROI while maintaining profitability.',
                },
                {
                    title: 'Campaign Structuring',
                    content:
                        'Organize your campaigns into logical structures based on product categories, performance goals, or targeted keywords. Proper campaign structuring facilitates easier management and optimization.',
                },
                {
                    title: 'Monitoring and Optimization',
                    content:
                        'Regularly monitor campaign performance metrics such as click-through rates, conversion rates, and ACoS (Advertising Cost of Sales). Optimize campaigns by adjusting bids, targeting, and ad creatives based on performance data.',
                },
                {
                    title: 'Competitor Analysis',
                    content:
                        "Keep an eye on your competitors' advertising strategies and performance. Analyze their ad creatives, keyword targeting, and pricing strategies to identify opportunities and stay competitive.",
                },
                {
                    title: 'Utilizing Amazon DSP',
                    content:
                        "Consider leveraging Amazon's Demand-Side Platform (DSP) for advanced targeting and retargeting capabilities across the web, not just within Amazon's ecosystem.",
                },
            ],
            underText: "By mastering these fundamental aspects of Amazon advertising and staying updated on industry trends, you can enhance your visibility, drive sales, and achieve sustainable growth on the platform.\n" +
                "For more in-depth insights and personalized strategies tailored to your business needs, consult with our team of Amazon advertising experts today!\n",
            type: 'Amazon',
            date: '2023-09-01',
        },
        {
            id: 2,
            title: 'Boost Your Amazon Listing Performance with These SEO Tips',
            explanation: "In the competitive landscape of Amazon, optimizing your product listings is essential for visibility and sales. Here are some SEO tips to enhance your Amazon listings:",
            image: blog2,
            description: [
                {
                    title: 'Keyword Research',
                    content:
                        'Start by conducting thorough keyword research using tools like Helium 10 or Jungle Scout. Identify relevant keywords with high search volume and include them strategically in your title, bullet points, and description.',
                },
                {
                    title: 'Compelling Title',
                    content:
                        'Craft a compelling title that includes your main keywords and highlights key features or benefits. Keep it concise yet descriptive to capture shoppers\' attention and improve click-through rates.',
                },
                {
                    title: 'Informative Bullet Points',
                    content:
                        'Use bullet points to outline the key features, benefits, and unique selling points of your product. Focus on readability and ensure each bullet point provides valuable information to potential buyers.',
                },
                {
                    title: 'Detailed Description',
                    content:
                        'Write a detailed product description that elaborates on the features, uses, and benefits of your product. Incorporate relevant keywords naturally and highlight any special offers, warranties, or guarantees to entice buyers.',
                },
                {
                    title: 'High-Quality Images',
                    content:
                        'Invest in high-quality product images that showcase your product from multiple angles and in different settings. Use clear, professional images that accurately represent your product and help build trust with potential buyers.',
                },
                {
                    title: 'Enhanced Brand Content (EBC)',
                    content:
                        'If eligible, take advantage of Enhanced Brand Content to create visually appealing and informative content for your product listing. Use EBC to tell your brand story, showcase additional product images, and highlight key features.',
                },
                {
                    title: 'A/B Testing',
                    content:
                        'Continuously monitor and test different elements of your listing, such as title, images, and bullet points, to identify what resonates best with your target audience. Use data-driven insights to refine and optimize your listing over time.',
                },
                {
                    title: 'Customer Reviews',
                    content:
                        'Encourage satisfied customers to leave positive reviews by providing exceptional customer service and following up with buyers after purchase. Positive reviews not only improve your product\'s credibility but also impact its ranking in Amazon search results.',
                },
            ],
            underText: "By implementing these SEO tips, you can improve the visibility, click-through rates, and conversion rates of your Amazon listings, ultimately driving more sales and growing your business on the platform.",
            type: 'SEO',
            date: "2023-12-24"
        },
        {
            id: 3,
            title: 'Optimizing Amazon Sponsored Product Ads for Maximum Results',
            explanation:
                'When it comes to Amazon advertising, Sponsored Product Ads are a game-changer for boosting product visibility and driving sales. Here are some strategies to optimize your Sponsored Product Ads for maximum impact:',
            image: blog3,
            description: [
                {
                    title: 'Targeted Keywords',
                    content:
                        'Identify relevant keywords that are closely related to your product and have high search volume. Incorporate these keywords into your ad campaigns to ensure your ads appear in relevant search results and attract potential customers.',
                },
                {
                    title: 'Compelling Ad Copy',
                    content:
                        'Craft compelling ad copy that highlights the unique features and benefits of your product. Use persuasive language and clear calls-to-action to encourage shoppers to click on your ads and make a purchase.',
                },
                {
                    title: 'Strategic Bidding',
                    content:
                        'Set your bidding strategy based on your advertising goals and budget. Monitor the performance of your ads and adjust your bids accordingly to maximize your return on investment (ROI) and achieve your desired advertising objectives.',
                },
                {
                    title: 'Targeted Audience',
                    content:
                        'Use targeting options such as product targeting, category targeting, and audience targeting to reach your ideal customers. Tailor your ad campaigns to specific demographics, interests, and purchasing behaviors to increase relevancy and effectiveness.',
                },
                {
                    title: 'Ad Placement',
                    content:
                        'Experiment with different ad placements, such as top of search, product detail pages, and rest of search, to determine which placements drive the highest conversion rates and ROI for your products.',
                },
                {
                    title: 'Ad Scheduling',
                    content:
                        'Optimize your ad scheduling to ensure your ads are displayed at times when your target audience is most active and likely to make a purchase. Adjust your ad schedules based on performance data to maximize exposure during peak shopping periods.',
                },
                {
                    title: 'Continuous Optimization',
                    content:
                        'Monitor the performance of your Sponsored Product Ads on a regular basis and make data-driven optimizations to improve ad performance over time. Test different ad creatives, targeting options, and bidding strategies to identify what works best for your products.',
                },
                {
                    title: 'Performance Tracking',
                    content:
                        'Track key performance metrics such as click-through rate, conversion rate, and advertising cost of sales (ACoS) to measure the effectiveness of your Sponsored Product Ads. Use this data to refine your ad campaigns and optimize your advertising strategy for better results.',
                },
            ],
            underText:
                'By implementing these strategies, you can optimize your Amazon Sponsored Product Ads to drive more traffic, conversions, and sales for your products on the platform.',
            type: 'Amazon',
            date: '2024-01-07',
        },
    ];

    const currentBlog = blogData.find((blog) => blog.id === parseInt(id));
    return (
        <MainLayout>
            <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
                <div className="mb-5 mx-auto">
                    <Typography color="white" variant="h3"
                                className="mb-2">
                        {currentBlog?.title}
                    </Typography>
                    <Typography color="white" variant="p" className="">
                        {currentBlog?.explanation}
                    </Typography>
                    <div className="text-gray-700 text-xs flex my-6 justify-between">
                        <div className="flex items-center">
                            <Link to="">
                                <Avatar
                                    src={raLogo}
                                    alt="ra digital"
                                    withBorder={true}
                                    className="w-12 h-12 mr-3 object-contain"
                                />
                            </Link>
                            <div className="text-sm">
                                <div
                                    className="text-gray-200 font-medium leading-none hover:text-indigo-600 transition duration-500 ease-in-out cursor-pointer">
                                    Ra Digital
                                </div>
                                <p className="text-gray-600 text-xs">
                                    {currentBlog?.date}
                                </p>
                            </div>
                        </div>
                        <div className="flex-row flex items-center justify-center gap-3">
                            <Link to="/">
                                <FaSquareXTwitter className="w-5 h-5 hover:text-[#1DA1F2] duration-300 transition hover:duration-300"/>
                            </Link>
                            <Link to="https://www.linkedin.com/company/radigital-io/about/?viewAsMember=true">
                                <FaLinkedin className="w-5 h-5 hover:text-[#0a66c2] duration-300 transition hover:duration-300"/>
                            </Link>
                            <Link to="/">
                                <FaTiktok className="w-5 h-5 hover:text-[#f7004d] duration-300 transition hover:duration-300"/>
                            </Link>
                            {/*<Link to="/">*/}
                            {/*    <FaFacebook className="w-5 h-5 hover:text-[#1877F2] duration-300 transition hover:duration-300"/>*/}
                            {/*</Link>*/}
                            <Link to="https://www.instagram.com/radigital_io">
                                <FaInstagram className="w-5 h-5 hover:text-[#E4405F] duration-300 hover:transition hover:hover:duration-300"/>
                            </Link>
                        </div>
                    </div>
                </div>

                {/*<div className="bg-cover h-64 text-center overflow-hidden object-contain"*/}
                {/*     style={{*/}
                {/*         height: '450px',*/}
                {/*         backgroundImage: `url(${currentBlog.image})`,*/}
                {/*     }}*/}
                {/*></div>*/}
                <img className="w-full sm:h-[600px] sm:object-fill object-cover rounded-md"
                     src={currentBlog?.image} alt={currentBlog?.image}/>
                <div className="mx-auto">
                    <div
                        className="my-3 bg-[#5A648D26] text-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                        <div className="px-3">
                            {currentBlog.description.map((step, index) => (
                                <div key={index} className="mb-6">
                                    <h2 className="text-xl font-bold my-2">{step?.title}</h2>
                                    <p className="text-base leading-8 my-2">{step?.content}</p>
                                </div>
                            ))}
                        </div>

                        <p className="text-base leading-8 my-5 px-3">{currentBlog?.underText}</p>
                        <div className="flex flex-wrap gap-2 px-3 pb-3">
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #SEO
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #Amazon
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #RaDigital
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #Tips
                            </Link>
                            <Link to="/"
                                  className="text-xs text-indigo-600 font-medium hover:text-gray-400 transition duration-500 ease-in-out">
                                #Marketing
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </MainLayout>
    );
}
