import React from 'react'
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import adsBanner from "../../../Assets/Images/story/socialMedia.svg"
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersMetaAds from "../../../Assets/Images/banners/META ADS.webp";

export default function MetaAds() {
    const bannerHeader = "META Ads Mastery for Your Brand";
    const bannerTxt = "Explore the potential of social media advertising with META. Our expertise spans platforms like Facebook and Instagram, enabling us to craft impactful campaigns that elevate your brand, engage your audience, and deliver meaningful results.";

    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersMetaAds}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            About META Ads
                        </Typography>
                        <Typography color="white" variant="lead">
                            META Ads represent a dynamic advertising landscape that includes Facebook and Instagram,
                            offering businesses unparalleled opportunities to connect with their target audience. Our
                            META Ads services are designed to harness the unique strengths of both platforms, creating a
                            comprehensive strategy that maximizes your brand's impact.
                        </Typography>
                    </div>
                </motion.div>
            </section>
            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h3" className="text-center nbXs:text-center">
                        Our Expertise
                    </Typography>
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "At Ra Digital, our team consists of seasoned and certified META Ads specialists who stay ahead
                        of industry trends and algorithm updates. With a focus on Facebook Ads and Instagram Ads, we
                        bring a wealth of experience to the table, ensuring your campaigns are not just seen but
                        remembered."
                    </Typography>
                </div>
            </section>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Services We Offer
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Campaign Development
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Crafting campaigns that align with your business goals is our forte. Whether you're
                                    looking to boost brand awareness, generate leads, or drive sales, our strategic
                                    approach tailors META Ads campaigns for optimal success on Facebook and Instagram.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Creative Ad Design
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Make a lasting impression with visually striking and compelling ad creatives. Our
                                    in-house creative team specializes in designing ads that seamlessly integrate with
                                    both Facebook and Instagram, capturing the attention of your target audience.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Audience Targeting and Segmentation
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Precision is key when it comes to reaching your audience. Utilizing advanced
                                    targeting options, we ensure your META Ads are seen by the right people on both
                                    Facebook and Instagram, maximizing the impact of your campaigns.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Ad Performance Analytics
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Stay informed with real-time insights into your campaign performance. Our experts
                                    monitor key metrics and deliver detailed analytics reports, empowering you to make
                                    data-driven decisions for your Facebook and Instagram Ads.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Cross-Platform Integration
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Create a unified brand experience across Facebook and Instagram. Our cross-platform
                                    integration strategy ensures a cohesive message, enhancing brand recognition and
                                    engagement on both social media platforms.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for META Ads?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for META Ads?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Expertise in Facebook and Instagram Ads: Our specialists have a proven track
                                        record in designing and managing successful campaigns on both platforms.
                                    </li>
                                    <li>
                                        Tailored Solutions: Recognizing the uniqueness of each business, we provide
                                        customized strategies aligned with your specific goals and target audience.
                                    </li>
                                    <li>
                                        Data-Driven Optimization: Our approach is grounded in data. We analyze and
                                        optimize your META Ads campaigns to continually enhance performance.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>


        </div>


    )
}