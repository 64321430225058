import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import amazonDsp from "../../../Assets/Images/story/teamGoal.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import improve from "../../../Assets/Images/story/improve.png";
import bannersDsp from "../../../Assets/Images/banners/Amazon DSP.webp";

export default function AmazonDsp() {
    const bannerHeader = "Transform your brand with Amazon DSP";
    const bannerTxt ="Elevate your brand reach with our Amazon DSP experts. We handle programmatic ads seamlessly, expanding your visibility across diverse online platforms."
    return (

        <div className="">
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersDsp}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Expert Amazon DSP Services for Targeted Advertising Success
                        </Typography>
                        <Typography color="white" variant="lead">
                            Amazon DSP, or Demand-Side Platform, is a robust advertising tool empowering marketers
                            to
                            programmatically purchase and showcase ads across Amazon's vast network, extending reach
                            to
                            potential customers both on and off the platform. At <b>Ra Digital</b> we boast a team
                            of
                            certified Amazon DSP experts. Armed with profound knowledge and practical experience,
                            our
                            specialists are committed to comprehending your distinct business objectives and
                            crafting
                            tailored Amazon DSP strategies to align with your marketing goals, ultimately enhancing
                            brand visibility and targeting specific audiences.
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Services We Offer
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Strategic Campaign Planning
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Tailored campaigns aligned with your advertising objectives, be it brand
                                    awareness,
                                    product launches, or sales growth. Our strategic planning sets the foundation
                                    for
                                    campaign success.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Creative Ad Development
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Our in-house creative team excels in producing visually compelling ad creatives
                                    across various formats. From display ads to videos, we ensure your brand
                                    captures
                                    attention and resonates with the target audience.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Audience Targeting and Segmentation
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Harnessing advanced techniques, we employ precise audience targeting and
                                    segmentation. By analyzing customer behavior and demographics, we optimize
                                    campaigns
                                    for maximum impact.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Performance Monitoring and Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Continuous monitoring is integral to success. Our experts analyze campaign
                                    performance in real-time, making adjustments to optimize results. Detailed
                                    reports
                                    and insights keep you informed about the progress of your Amazon DSP campaigns.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    5
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Off-Amazon Reach Expansion
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Our services extend beyond the Amazon ecosystem, encompassing advertising on
                                    external websites, apps, and platforms. This ensures your brand reaches a
                                    broader
                                    audience, maximizing its impact.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Amazon DSP?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Ra Digital Transformations Lead to Remarkable Outcomes
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Expertise: Our team comprises certified Amazon DSP specialists with a proven
                                        track
                                        record of delivering successful campaigns.
                                    </li>
                                    <li>
                                        Custom Solutions: We understand that every business is unique. Our approach
                                        is
                                        tailored to your specific goals and challenges.
                                    </li>
                                    <li>
                                        Transparent Reporting: Receive detailed reports and insights, allowing you
                                        to
                                        track
                                        the performance and success of your campaigns.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-max">
                        <img src={improve} alt="experts"
                             className="w-full max-h-[325px] nbXs:max-h-max object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        Ready to elevate your advertising game with Amazon DSP? Contact Ra Digital today to discuss
                        how we can help you achieve your business objectives through strategic and effective Amazon DSP
                        campaigns.
                    </Typography>
                </div>
            </section>
        </div>


    )
}