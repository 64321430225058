import React, {createElement, Fragment, useEffect, useState} from "react";
import {
    Navbar,
    Typography,
    MenuItem,
    Card,
    Drawer, Collapse, CardBody
} from "@material-tailwind/react";
import {
    Square3Stack3DIcon,
    ChevronDownIcon,
} from "@heroicons/react/24/solid";
import mainLogo from "../../Assets/Images/RaLogos/raDigitalWhite.webp";
import {Link} from "react-router-dom";
import {ScoolButton} from "../Buttons/ScoolButton";
import {UsersIcon} from "@heroicons/react/24/outline";
import {PiFilesThin} from "react-icons/pi";
import {AnimatePresence, motion} from "framer-motion";
import {DrawerNav} from "./DrawerNav";
import {BsMailboxFlag } from "react-icons/bs";

// Services menu items
const servicesMenuItems = [
    {
        title: "Amazon Advertising",
        to: "#",
        subMenu: [
            {
                title: "Amazon PPC",
                to: "/services/amazon-ads",
            },
            {
                title: "Amazon DSP",
                to: "/services/amazon-dsp",
            },

        ],
    },
    {
        title: "Amazon Store Management",
        to: "#",
        subMenu: [
            {
                title: "A+ Content",
                to: "/services/a-plus-content",
            },
            {
                title: "SEO (Backend,Bulletpoint etc.)",
                to: "/services/seo",
            },
            {
                title: "Brand Registry",
                to: "/services/brand-registry",
            },
            {
                title: "Store Page Design",
                to: "/services/store-page-design",
            },
        ],
    },
    {
        title: "Marketplace Management",
        to: "#",
        subMenu: [
            {
                title: "Walmart Market Management",
                to: "/services/walmart-market-management",
            },
            {
                title: "Etsy Market Management",
                to: "/services/etsy-market-management",
            },
            {
                title: "Trendyol Store Management",
                to: "/services/trendyol-store-management",
            },
            {
                title: "Shopify Market Management",
                to: "/services/shopify-market-management",
            },
        ],
    },
    {
        title: "D2C Solutions",
        to: "#",
        subMenu: [
            {
                title: "Google Ads",
                to: "/services/google-ads",
            },
            {
                title: "Meta Ads Management",
                to: "/services/meta-ads",
            },
            // {
            //     title: "Off-Amazon marketing",
            //     to: "/services/off-amazon-marketing",
            // },
            {
                title: "Website Development",
                to: "/services/website-management",
            },
            {
                title: "Social Media Management",
                to: "/services/social-media-management",
            },
            {
                title: "Inventory Management",
                to: "/services/inventory-management",
            }
        ],
    },
];
// Resources menu items
const resourcesMenuItems = [
    // {
    //     title: "Free PPC Audit",
    //     description: "Boost your online presence with our digital marketing services.",
    //     to: "/resources/free-ppc-audit",
    //     icon: BuildingStorefrontIcon,
    //     color: "purple",
    // },
    {
        title: "Blog",
        to: "/resources/blog",
    },
    // {
    //     title: "Partners",
    //     to: "/resources/partners",
    // },
    {
        title: "References",
        to: "/resources/references",
    },
    {
        title: "Case Studies",
        to: "/resources/case-studies",
    }
];


function Services() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const renderItems = servicesMenuItems.map((item, index) => (
        <div className="flex flex-col items-center p-2 gap-3 bg-[#171424] rounded-lg ">
            <Typography variant="h6" color="white" className="flex items-center font-bold">
                {item?.title}
            </Typography>
            <div className="flex flex-col gap-2 w-full ">
                {
                    item?.subMenu.map((subItem, subIndex) => (
                        <Link to={subItem.to} className="borderAnimate rounded-lg bg-[#050213] px-3 py-1.5">
                            <Typography variant="small" color="white"
                                        className="truncate-text-2">
                                {subItem.title}
                            </Typography>
                        </Link>
                    ))
                }
            </div>
        </div>
    ));
    return (
        <Fragment>
            <div
                className="relative"
                onMouseEnter={() => setIsMenuOpen(true)}
                onMouseLeave={() => setIsMenuOpen(false)}
            >
                <motion.div
                    className="w-full pt-[9px] pb-2 px-3 rounded-md text-start leading-tight cursor-pointer
                    select-none hover:bg-opacity-80 focus:bg-blue-gray-50 focus:bg-opacity-80 active:bg-blue-gray-50 active:bg-opacity-80
                    hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900 outline-none flex items-center gap-2 md:rounded-full"
                    whileHover={{backgroundColor: 'rgba(119, 108, 166, 0.8)', color: '#121212'}}
                >
                    <Square3Stack3DIcon className="h-[18px] w-[18px] text-white"/>
                    <span className="text-white"> Services </span>
                    <ChevronDownIcon
                        strokeWidth={2}
                        className={`h-3 w-3 text-white ${isMenuOpen ? 'rotate-180' : ''}`}
                        transition={{duration: 0.3}}
                    />
                </motion.div>
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{opacity: 0, y: 5}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -5}}
                            className="absolute w-max z-[9999] grid lg:grid-cols-4 grid-cols-2 gap-2 p-2 lg:-left-[400px] -left-[200px] shadow-inner
                                       border-transparent bg-[#1C1927FF] rounded-md"
                            onMouseEnter={() => setIsMenuOpen(true)}
                            onMouseLeave={() => setIsMenuOpen(false)}
                        >
                            {renderItems}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </Fragment>
    );
}


function Resources() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const renderItems = resourcesMenuItems.map((item, index) => (
        <div className="flex flex-col items-center p-2 gap-3 bg-[#050213] rounded-lg ">
            <div className="flex flex-col gap-2 w-full ">
                <Link to={item?.to} className="borderAnimate  p-1">
                    <Typography variant="small" color="white"
                                className="truncate-text-2">
                        {item?.title}
                    </Typography>
                </Link>
            </div>
        </div>
    ));
    return (
        <Fragment>
            <div
                className="relative"
                onMouseEnter={() => setIsMenuOpen(true)}
                onMouseLeave={() => setIsMenuOpen(false)}
            >
                <motion.div
                    className="w-full pt-[9px] pb-2 px-3 rounded-md text-start leading-tight cursor-pointer
                    select-none hover:bg-opacity-80 focus:bg-blue-gray-50 focus:bg-opacity-80 active:bg-blue-gray-50 active:bg-opacity-80
                    hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900 outline-none flex items-center gap-2 md:rounded-full"
                    whileHover={{backgroundColor: 'rgba(119, 108, 166, 0.8)', color: '#121212'}}
                >
                    <PiFilesThin className="h-[18px] w-[18px] text-white"/>
                    <span className="text-white"> Resources </span>
                    <ChevronDownIcon
                        strokeWidth={2}
                        className={`h-3 w-3 text-white ${isMenuOpen ? 'rotate-180' : ''}`}
                        transition={{duration: 0.3}}
                    />
                </motion.div>
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{opacity: 0, y: 5}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -5}}
                            className="absolute w-max z-[9999] grid grid-cols-2 gap-2 p-2 left-0 shadow-inner
                                       border-transparent bg-[#1c1927] rounded-md"
                            onMouseEnter={() => setIsMenuOpen(true)}
                            onMouseLeave={() => setIsMenuOpen(false)}
                        >
                            {renderItems}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </Fragment>
    );
}

function Contact() {
    return (
        <Link to="/contact" key="/contact">
            <Typography
                key="/contact"
                as="text"
                variant="small"
                color="white"
                className="font-medium text-white font-montserrat"
            >
                <MenuItem className="flex items-center gap-2 md:rounded-full
                         hover:bg-[#776ca6]">
                    {createElement(BsMailboxFlag , {className: "h-[18px] w-[18px] text-white"})}{" "}
                    <span className="text-white"> Contact</span>
                </MenuItem>
            </Typography>
        </Link>
    );

}

// nav list component
const navListItems = [
    {
        label: "About",
        icon: UsersIcon,
        to: "/about",
    },
    // {
    //     label: "Contact",
    //     icon: UsersIcon,
    //     to: "/contact",
    // }
];

function NavList() {
    return (
        <ul className="mt-2 mb-4 flex flex-col gap-2 md:mb-0 md:mt-0 md:flex-row md:items-center">
            <Services/>
            {navListItems.map(({label, icon, to}, key) => (
                <Link to={to} key={key}>
                    <Typography
                        key={label}
                        as="text"
                        variant="small"
                        color="white"
                        className="font-medium text-white font-montserrat"
                    >
                        <MenuItem className="flex items-center gap-2 md:rounded-full
                         hover:bg-[#776ca6]">
                            {createElement(icon, {className: "h-[18px] w-[18px] text-white"})}{" "}
                            <span className="text-white"> {label}</span>
                        </MenuItem>
                    </Typography>
                </Link>
            ))}
            <Resources/>
            <Contact/>
        </ul>
    );
}

export function BasicNav() {
    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const toggleOpen = () => setOpen((cur) => !cur);

    useEffect(() => {
        window.addEventListener(
            'resize',
            () => window.innerWidth >= 960 && setIsMobileDrawerOpen(false)
        );

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className="sticky top-0 z-[9995] transition duration-200">
            <div>
                {windowWidth > 768 ? (
                    <Navbar
                        className="max-w-full rounded-none py-2 px-4 md:px-8 md:py-4 bg-mainColor !border-none shadow-3xl"
                    >
                        <div className="flex items-center justify-between h-full">
                            <Link to="/" className="cursor-pointer">
                                <img src={mainLogo} alt="" className="w-44 h-10 object-contain"/>
                            </Link>
                            <div className="hidden md:block">
                                <NavList/>
                            </div>
                            <ScoolButton
                                link={'contact'}
                                buttonText={'Free PPC Audit'}
                                buttonClassName="hidden md:inline-block backgroundGradient text-white font-medium py-2 px-4 rounded transition-all active:scale-95"
                                setOffset={-72}
                            />
                        </div>
                    </Navbar>
                ) : (
                    <div className="">
                        {/*<NavbarWithMegaMenu/>*/}
                        <DrawerNav servicesMenuItems={servicesMenuItems} resourcesMenuItems={resourcesMenuItems}/>
                    </div>

                )}
            </div>
            {isMobileDrawerOpen && (
                <Drawer
                    placement="right"
                    open={isMobileDrawerOpen}
                    onClose={() => setIsMobileDrawerOpen(false)}
                    className="p-4 bg-black"
                >
                    <div>
                        <ul className="mt-2 mb-4 flex flex-col gap-2 md:mb-0 md:mt-0 md:flex-row md:items-center">
                            <MenuItem onClick={toggleOpen}
                                      className="flex items-center gap-2 font-medium text-white md:hidden">
                                <Square3Stack3DIcon className="h-[18px] w-[18px] text-white"/>{" "}
                                <span className="text-white"> Services{" "}</span>
                            </MenuItem>
                            <Collapse open={open} onClick={toggleOpen}>
                                <Card className="my-4 mx-auto w-8/12">
                                    <CardBody>
                                        <Typography>
                                            RA Digital
                                        </Typography>
                                    </CardBody>
                                </Card>
                            </Collapse>


                            {navListItems.map(({label, icon, to}, key) => (
                                <Link to={to} key={key}>
                                    <Typography
                                        key={label}
                                        as="text"
                                        variant="small"
                                        color="white"
                                        className="font-medium text-white"
                                    >
                                        <MenuItem className="flex items-center gap-2 md:rounded-full
                                            hover:bg-[#776ca6]">
                                            {createElement(icon, {className: "h-[18px] w-[18px] text-white"})}{" "}
                                            <span className="text-white"> {label}</span>
                                        </MenuItem>
                                    </Typography>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </Drawer>
            )}
        </div>


    );
}