import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import websiteDesign from "../../../Assets/Images/story/websiteDesign.svg";
import website from "../../../Assets/Images/banner/website.jpg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import {PricingCard} from "../../../Components/Cards/pricingCard";
import bannersWebsite from "../../../Assets/Images/banners/Web Development.webp";

export default function Website() {
    const bannerHeader = "Crafting Exceptional Websites";
    const bannerTxt = "Explore Ra Digital's website development services for a powerful online presence. Our expert team crafts exceptional websites, delivering seamless user experiences that drive engagement and conversions.";
    return (

        <div>
            <ServiceDetailBanner bannerHeader={bannerHeader} bannerTxt={bannerTxt} bannerImg={bannersWebsite}/>
            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <Typography color="white" variant="h3" className="nbXs:text-center">
                            Unlock Digital Brilliance with Ra Digital!
                        </Typography>
                        <Typography color="white" variant="lead">
                            Step into a world of endless digital potential! Our dedicated section is your gateway to
                            online excellence. With a team of experts, we work tirelessly to enhance your digital
                            presence and drive success. Let's make your digital dreams a reality!
                        </Typography>
                    </div>
                </motion.div>
            </section>

            <section className="py-6">
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className=" mx-auto flex flex-col justify-center items-center ">
                    <div className="flex flex-col justify-center items-center gap-3 px-6 max-w-6xl">
                        <img src={website} alt="" className="rounded-md"/>
                    </div>
                </motion.div>
            </section>

            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Services We Offer
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Web Design and Development
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Immerse your audience in a visually captivating online experience. Our expert
                                    designers and developers collaborate to craft websites that not only showcase your
                                    brand aesthetics but also prioritize functionality for a seamless user journey.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    E-Commerce Solutions
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Maximize your online sales potential with our tailored e-commerce solutions. Whether
                                    you're launching a new online store or enhancing an existing one, our services are
                                    designed to optimize user engagement, increase conversions, and maximize revenue.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Responsive Design
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    In a world dominated by mobile browsing, we prioritize responsive design. Our
                                    websites ensure an optimal viewing and interaction experience across devices, from
                                    desktops to tablets and smartphones.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    4
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Performance-Driven Development
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Experience websites that not only look great but also perform exceptionally. Our
                                    focus goes beyond aesthetics to deliver a seamless user experience that maximizes
                                    engagement and functionality.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <PricingCard/>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Elevate your brand online with Ra Digital where design gets a sprinkle of magic! Our
                        user-centric wizardry fuels business growth, making digital success a joyride. Join the fun
                        where innovation meets design, and let Ra Digital sprinkle some website excellence magic on your
                        brand!"
                    </Typography>
                </div>
            </section>
        </div>


    )
}