import React from 'react';
import ServiceDetailBanner from "../../../Components/Banner/ServiceDetailBanner";
import trendyolStoreManagementBanner from "../../../Assets/Images/story/growingMoney.svg";
import {motion} from "framer-motion";
import {
    Timeline, TimelineBody, TimelineConnector, TimelineHeader, TimelineIcon, TimelineItem, Typography
} from "@material-tailwind/react";
import trendyolOptimizationImg from "../../../Assets/Images/story/improve.png";
import {useParams} from "react-router-dom";
import bannersTrendyol from "../../../Assets/Images/banners/Trenyol Store Management.webp";

export default function TrendyolStoreManagement() {
    let {service_name} = useParams();
    service_name = "trendyol_store_management" ? "Trendyol Store Management" : "Trendyol Store Management";
    const bannerTxt = "Maximize your Trendyol store's potential with Ra Digital. Our specialized services optimize your Trendyol account, boost visibility, and drive sales. Explore our tailored solutions for a thriving presence on the Trendyol platform.";

    return (
        <div>
            <ServiceDetailBanner service_name={service_name} bannerTxt={bannerTxt} bannerImg={bannersTrendyol}/>
            <section className="py-6 flex justify-center ">
                <div className="xl:w-[55%] lg:w-[50%] md:w-[65%] p-8 shadow-basicShadow">
                    <Typography color="white" variant="h4" className="nbXs:text-center pb-3">
                        Trendyol Store Optimization
                    </Typography>
                    <Timeline>
                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    1
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Store Enhancement
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Elevate your Trendyol store for maximum impact. Our store enhancement services
                                    ensure that your products are showcased effectively, increasing their appeal to
                                    potential customers.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    2
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Product Listing Optimization
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody className="pb-8">
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Optimize your Trendyol product listings through effective strategies. We enhance
                                    your product listings to align with Trendyol's search algorithms, ensuring your
                                    products receive maximum visibility.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>

                        <TimelineItem>
                            <TimelineConnector/>
                            <TimelineHeader>
                                <TimelineIcon className="w-[32px] h-[32px] text-center text-sm">
                                    3
                                </TimelineIcon>
                                <Typography variant="h5" className="text-slate-300 shadowText">
                                    Trendyol SEO Strategies
                                </Typography>
                            </TimelineHeader>
                            <TimelineBody>
                                <Typography color="gary" className="font-normal text-gray-600">
                                    Enhance the discoverability of your products through effective SEO strategies. We
                                    optimize your product listings to align with Trendyol's search algorithms, ensuring
                                    your products are prominently featured.
                                </Typography>
                            </TimelineBody>
                        </TimelineItem>
                    </Timeline>
                </div>
            </section>

            <section>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.6}}
                    className="flex flex-col lg:flex-row flex-col justify-center items-center gap-3 px-6 md:max-w-6xl mx-auto py-12"
                >
                    <Typography color="white" variant="h3"
                                className="lg:hidden block text-center nbXs:text-center pb-3">
                        Why Choose Ra Digital for Trendyol Store Management?
                    </Typography>
                    <div className="lg:px-6 lg:w-[55%] w-full">
                        <div className="flex flex-col justify-center items-center gap-3 w-full">
                            <Typography color="white" variant="h3"
                                        className="lg:block hidden text-center nbXs:text-center pb-3">
                                Why Choose Ra Digital for Trendyol Store Management?
                            </Typography>
                            <Typography color="white" variant="lead">
                                <ul className="list-disc">
                                    <li>
                                        Trendyol Expertise: Our team specializes in navigating and optimizing Trendyol
                                        store accounts.
                                    </li>
                                    <li>
                                        Comprehensive Solutions: From store enhancement to SEO strategies, we offer a
                                        holistic approach to ensure your success on the Trendyol platform.
                                    </li>
                                    <li>
                                        Customized Strategies: Recognizing the uniqueness of each brand, we tailor our
                                        services to align with your specific goals and brand identity.
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full max-h-[325x] nbXs:max-h-[200px]">
                        <img src={trendyolOptimizationImg} alt="Trendyol Optimization"
                             className="w-full max-h-[325px] nbXs:max-h-[200px] object-cover drop-shadow-xl xl:object-cover object-contain"/>
                    </div>
                </motion.div>
            </section>

            <section
                className="bg-gradient-to-r from-[#64748b] via-[#414e63] to-[#202c3e] py-8 rounded-md shadow-2xl mb-6">
                <div className="flex flex-col justify-center items-center gap-3 lg:px-6 px-2 max-w-6xl mx-auto">
                    <Typography color="white" variant="h4"
                                className="text-center nbXs:text-center pb-3 italic lg:text-xl text-lg nbXs:text-base">
                        "Ready to elevate your brand's presence on Trendyol? Reach out to Ra Digital today to discover
                        how
                        our comprehensive services can unlock your Trendyol store's full potential, boost sales, and
                        drive
                        enduring success on the Trendyol platform."
                    </Typography>
                </div>
            </section>
        </div>
    );
}
